const resources = require('./labels.json');

export interface ILabelResource {
    id: string;
    label: string;
    placeholder?: string;
}

class ResourcesClass {

    private static _instance: ResourcesClass;

    private _labels: Map<string, ILabelResource> = new Map();

    private constructor() {
        Object.keys(resources).forEach(key => {
            const [, _label, labelOrPlaceholder] = key.split(':');
            this._labels.set(key, {
                id: key, label: _label, [labelOrPlaceholder]: resources[key]
            });
        });
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public get(view: string, id: string, property: 'label' | 'placeholder'): string {
        const key = `${view}:${id}:${property}`;
        const labelResource = this._labels.get(key);
        if (!labelResource) throw new Error(`Resource with key ${key} not found`);
        return labelResource[property] || '';
    }
}

export const Resources = ResourcesClass.Instance;
