import { FC, createContext, useContext, useState, PropsWithChildren } from 'react';

/**
 * Layout context state type
 */
export type ILayoutState = {
  showAboutModal: boolean; // Flag that defines if about screen modal is shown or not
}

/**
 * Layout context props type
 */
type LayoutContextProps = {
  state: ILayoutState;
  updateLayoutState: (updates: Partial<ILayoutState>) => void;
  toggleAboutModal: () => void;
}

/**
 * Context initial values
 */
const initialLayoutContextValues: LayoutContextProps = {
  state: {
    showAboutModal: false,
  },
  updateLayoutState: (): void => {
  },
  toggleAboutModal: (): void => {
  },
};
export const LayoutContext = createContext(initialLayoutContextValues);

export const LayoutProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const [ state, setState ] = useState<ILayoutState>(initialLayoutContextValues.state);

  /**
   * Updates the layout context state values.
   * @param {Partial<ILayoutState>} updates an object containing new values for the context state
   */
  const updateLayoutState = (updates: Partial<ILayoutState>) => {
    const updatedState = { ...state, ...updates } as ILayoutState;
    setState(updatedState);
  };

  /**
   * Toggles the about modal visibility.
   */
  const toggleAboutModal = () => {
    const updatedState = { ...state, showAboutModal: !state.showAboutModal } as ILayoutState;
    setState(updatedState);
  }

  return <LayoutContext.Provider
    value={ {
      state,
      updateLayoutState: updateLayoutState,
      toggleAboutModal: toggleAboutModal,
    } }
  >
    { children }
  </LayoutContext.Provider>;
};






