import { createCipheriv, createDecipheriv, randomBytes } from "crypto";
const LENGTH_KEYS = {
  key: 32,
  iv: 16
};
export module EncryptHelper {
  export const encrypt = (toCypher: string): string => {

    const key = randomBytes(LENGTH_KEYS.key);
    const iv = randomBytes(LENGTH_KEYS.iv);
    const cipher = createCipheriv('aes256', key, iv);

    const encryptedMessage = cipher.update(toCypher, 'utf8', 'hex') + cipher.final('hex');

    const stringKey = buffToString(key);
    const stringIv = buffToString(iv);

    const indexedStrings: string[] = [stringKey, encryptedMessage, stringIv].map((string: string, index: number) => `${string}${index}`);
    return indexedStrings.join(':');
  };

  export const decrypt = (message: string): string => {
    const [key, encryptedMessage, iv] = unIndexString(message);
    const bufKey: Buffer = keyToBuffer(key);
    const bufIv: Buffer = keyToBuffer(iv);

    const decipher = createDecipheriv("aes256", bufKey, bufIv);
    return (
      decipher.update(encryptedMessage, "hex", "utf8") + decipher.final("utf8")
    );
  };

  const keyToBuffer = (key: string): Buffer => {
    return Buffer.from(key, "hex");
  };

  const unIndexString = (indexedString: string): string[] => {
    const indexedStrings = indexedString.split(":");
    return indexedStrings.map((string: string) => sliceString(string));
  };

  const sliceString = (str: string): string => str.slice(0, -1);

  const buffToString = (buffer: Buffer): string => {
    return buffer.toString('hex');
  };
}
