import './MediaInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";


export const MediaInteractionCard = (props: InteractionCardProps) => {
    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'MEDIA','label')}
            code={props.code}
            icon={{
                name: "note",
                backgroundColor: '#E74C3B'
            }}
            onPress={()=>{}}
        >
            {!!props.interaction.EntityFields?.dsTitle && <div className="MediaInteractionCard">
                <label>{props.interaction.EntityFields?.dsTitle}</label>
            </div>}
        </GenericInteractionCard>
    );
}