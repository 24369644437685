import './TaskInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";


export const TaskInteractionCard = (props: InteractionCardProps) => {
    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'TODOI','label')}
            code={props.code}
            previousStatus={props.previousStatus}
            newStatus={props.newStatus}
            icon={{
                name: "playlist-add-check",
                backgroundColor: '#E74C3B'
            }}
            interactionDate={props.interactionDate}
            userName={props.userName}
            onPress={()=>{}}
        >
            {!!props.interaction.EntityFields?.nmToDoInstance && <div className="TaskInteractionCard-name">
                <label>{props.interaction.EntityFields?.nmToDoInstance}</label>
            </div>}
            {!!props.interaction.EntityFields?.nmClassification && <div className="TaskInteractionCard-classification">
                <label>{props.interaction.EntityFields?.nmClassification}</label>
            </div>}            
        </GenericInteractionCard>
    );
}