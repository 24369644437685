import './PaymentInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';


export const PaymentInteractionCard = (props: InteractionCardProps) => {
    const language = (window.navigator as any).userLanguage || window.navigator.language;
    const numberFormatOptions: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: props.interaction.EntityFields?.cdCurrency ?? 'USD',
        minimumFractionDigits: props.interaction.EntityFields?.nrDecimalPlaces ?? 2
      };

    const numberFormat = new Intl.NumberFormat(language, numberFormatOptions);

    const types = props.interaction.EntityFields?.nmPaymentTitleType?.map((p: { nmPaymentTitleType: any; }) => p.nmPaymentTitleType).join("; ");

    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'PAYME','label')}
            code={props.code}
            previousStatus={props.previousStatus}
            newStatus={props.newStatus}
            icon={{
                name: "account-balance-wallet",
                backgroundColor: '#2FCC71'
            }}
            onPress={()=>{}}
        >
            <div className="PaymentInteractionCard">
                {!!props.interaction.EntityFields?.vlPayment && <div className="payment-value">
                    <label>{`${numberFormat.format(props.interaction.EntityFields?.vlPayment)}`}</label>
                </div>}
                <div className="payment-interaction-date-and-type">
                    <label>{`${moment(props.interactionDate).format('ddd, D MMM')}`}</label>
                    {!!types && <FiberManualRecordIcon className="payment-interaction-dot-icon"/>}                        
                    {!!types && <label>{types}</label>}
                </div>
            </div>

        </GenericInteractionCard>
    );
}