import axios from "axios";
import { InputSearchGroup, InputSearchOption } from "components/InputSearch/InputSearch";
import { Endpoints } from "config/endpoints";
import { capitalize } from "lodash";
import { ISearchConfig } from "models/Search/SearchConfig";
import { ISearchResult } from "models/Search/SearchResult";
import { SEARCH_RELATIVE_PATH_BY_ENTITY, SupportedSearchEntity } from "Search";

const data = require('../../mocks/api/search/all.json');

interface ISearchService {
    getSearchResults(params: { q: string }): Promise<InputSearchGroup[]>;
    getSearchConfig(): Promise<ISearchConfig[]>;
    getSearch(params: { q: string, searchConfig: ISearchConfig[], token?: string }): Promise<ISearchConfig[]>;
}

const searchServiceUrl = Endpoints.getEndpoint("REACT_APP_SEARCH_ENDPOINT");

export const SearchService: ISearchService = {
    getSearchResults: async (params): Promise<InputSearchGroup[]> => {
        const {q} = params;

        if(!params.q) return [];

        // TODO: update map according o what BE real service delivers
        const mappedData: InputSearchGroup[] = Object.keys([]).map((entity: any) => {
            return {
                label: capitalize(entity),
                options: data[entity].map((props: any) => {
                    //Find property that matches query
                    const matches = Object.keys(props).filter(key => new RegExp(q.replace(/[-\/\\^$*+?.()|[\]{}]/gi, '\\$&'), 'gi').test(props[key]));
                    const keyMatches: string = matches.join(' - ');
                    const contentMatches: string =  matches.map(key => props[key]).join(' - ')

                    return {
                        content: contentMatches, 
                        type: `${keyMatches}`,
                        entity,
                        relativePath: SEARCH_RELATIVE_PATH_BY_ENTITY[entity as SupportedSearchEntity] 

                    } as InputSearchOption
                })
            };
        }).filter(group => group.options.length > 0);
        return mappedData;
    },

    getSearchConfig: async (): Promise<ISearchConfig[]|undefined> => {
        const { data } = await axios.get<any[]>(`${searchServiceUrl}search/config`);
        return data as ISearchConfig[];
    },

    getSearch: async (params): Promise<ISearchConfig[]> => {
        const { q, searchConfig, token } = params;
        return searchConfig.reduce((acc: ISearchConfig[],curr)=>{
            if(curr.status === true){
                const url = `${ searchServiceUrl }search/index`;
                const result = axios.post<ISearchResult>(url, {
                    ...curr,
                    search: q,
                    continuationToken: token
                });
                acc.push({
                    ...curr,
                    result
                });
            }
            return acc;
        },[])
    }
} as ISearchService;
