import useAccessToken from 'hooks/useAccessToken';
import React from "react";

export interface RestrictedComponentProps {
    accessLevel: string;
    children: React.ReactNode;
}
export const RestrictedComponent = (props: RestrictedComponentProps) => {
    const { expired, hasAccessLevel } = useAccessToken(props.accessLevel);
    return <>
        {!expired && hasAccessLevel() ? props.children : <></>}
    </>;
}