import { Typography } from "@mui/material";
import "./ColorBadge.scss";

export type ColorBadgeProps = {
    text: string;
    semanticValue: string;
    fontSize?: string;
    fontWeight?: string;
    width?: string;
    height?: string;
}

export enum BadgeSemantic {
    APPRO,
    INPROGRESS,
    RECEIVED,
    PLANNED,
    UNKNOWN,
    PAUSED,
    NEUTRAL,
    CANCELLED,
    ERROR,
    REJECTED,
    REJEC,
    BLOCKED,
    EXPIRED,
    NOTGOOD,
    FINALIZED,
    SENT,
    SETTLED,
    SUBMITTED,
    FINALIZING,
    GOOD,
    PENDING,
    DRAFT,
    SUSPENDED
}

export const ColorBadge = (props: ColorBadgeProps) => {

    const text = props.text;
    let semanticValue = props.semanticValue?.toUpperCase();

    semanticValue = Object.values(BadgeSemantic).includes(semanticValue) ? semanticValue : "UNKNOWN";

    return (
        <div className="color-badge" style={{ height: props.height, width: props.width}}>
            <div className={`badge-container ${semanticValue}`} >
                <Typography style={{height: props.height, width: props.width, fontSize: props.fontSize, fontWeight: props.fontWeight}} noWrap={true} align="center" className={`badge-text ${semanticValue}`}>{text}</Typography>
            </div>
        </div>
    )

}
