import { useEffect, useState } from "react";
import { FilterValue } from "../GenericFilter";
import { FormControlLabel, Switch } from "@mui/material";
import "./ToogleFilter.scss";

export interface IToogleFilter {
    fieldName: string;
    label: string;
    defaultValue?: boolean;
}

export interface IToogleProps {
    toogle: IToogleFilter;
    filterId: string;
    value?: boolean;
    onFilterChange: (filterValue: FilterValue, filterId: string) => any;
}

const ToogleFilter = (props: IToogleProps) => {
    const {
        toogle,
        filterId,
        value = toogle.defaultValue,
        onFilterChange = (filterValue: FilterValue, filterId: string) => { }
    } = props;

    const [toogleValue, setToogleValue] = useState<boolean>(toogle.defaultValue || false);

    useEffect(() => {
        onFilterChange({
            value: toogleValue,
            field: toogle.fieldName
        }, filterId)
    }, [toogleValue])

    return (
        <div className="ToogleFilter">
            <FormControlLabel
                id={`ToogleFilter__${toogle.fieldName}`}
                key={`ToogleFilter__${toogle.fieldName}`}
                className="toggle"
                value={value}
                control={<Switch checked={toogleValue} color="primary" />}
                label={toogle.label}
                onChange={() => setToogleValue(!toogleValue)}
                labelPlacement="start"
            />
        </div >
    );
};

export default ToogleFilter;//TODO: Rename to "ToggleFilter"
