import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Resources } from "../../resources/Resources";
import { Icon } from "components/Icon/Icon";
import "./BetaTest.scss";

import AppMenu from "AppMenu.json";
import AppRoutes from "AppRoutes.json";
import { UsersService } from "services/UsersService";
import { useAppState } from "context/StateProvider";
import useAccessToken from "hooks/useAccessToken";
import { useHistory } from "react-router-dom";

interface ConfirmProps {
  modalVisible: boolean;
  alertVisible: boolean;
  onBlur: () => any;
  iconRef: any;
  onConfirmCallback?: (param?: any) => any;
  onUpdateBetaOption: (param?: any) => any;
  loading: boolean;
  betaPreferences: any;
}

export const BetaTest = (props: ConfirmProps) => {
  const { onUpdateBetaOption, onConfirmCallback = () => {}, modalVisible, onBlur, iconRef, loading, betaPreferences } = props;
  const { identity } = useAppState();
  const history = useHistory();

  const { hasAccessLevel } = useAccessToken();
  const findAccessLevel = useCallback((path) => AppRoutes.restricted.find(r => r.path === path)?.accessLevel!, [AppRoutes]);

  const [alertVisible, setAlertVisible] = useState<boolean>(false)
  const [betaMenus, setBetaMenus] = useState(AppMenu.filter(x => x.beta === true).map(x => {
    return {
      ...x,
      enabled: false,
      visible: hasAccessLevel(findAccessLevel(x.path))
    }
  }))
  const [returnHome, setReturnHome] = useState<boolean>(false);

  const onConfirm = () => {
    const item = betaMenus.find(x=>x.path == window.location.pathname && x.enabled !== true);
    if(item)
      handleUpdateItem(item.title)

    setAlertVisible(false);
    onConfirmCallback();
  }

  useEffect(()=>{
    if(!betaPreferences) return;
    Object.keys(betaPreferences).map((item:string)=>{
      if(betaPreferences[item] && betaMenus.find(x=>x.title == item)?.enabled != true){
        handleUpdateItem(item)
      }
      
      if(!betaPreferences[item] && betaMenus.find(x=>x.path == window.location.pathname && x.title == item)){
        setAlertVisible(true);
      }
    });
  },[betaPreferences])

  useEffect(()=>{
    onUpdateBetaOption(betaMenus);
    
    if(identity?.id && loading == false){
      UsersService.updateUserPreferences({beta:betaMenus.reduce((acc: Object,item)=>{
        if(hasAccessLevel(findAccessLevel(item.path)))
          return {...acc, [item.title]: item.enabled};

        return acc;
      },{})}).then(()=>{
        if(returnHome)
          history.push(`/`);  
      })
    }
  },[betaMenus])

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref?.current as any).contains(event.target as Element) && iconRef.current && !(iconRef?.current as any).contains(event.target as Element)) {
        onBlur();
      }
    }
    const removeWindowEventListener = () => document.removeEventListener("mousedown", handleClickOutside);
    const addWindowEventListener = () => document.addEventListener("mousedown", handleClickOutside);

    (modalVisible ? addWindowEventListener : removeWindowEventListener)();
    return () => removeWindowEventListener();
  }, [ref, modalVisible]);

  const handleUpdateItem = (itemTitle: string) => {
    setBetaMenus(prev=>{
      const new_ = prev.map(menu=>{
        let menu_ = menu;
        if(menu.title === itemTitle){
          menu_ = {...menu, enabled: !menu.enabled}
          if(!menu.enabled === false && menu.path === window.location.pathname){
            setReturnHome(true);
          }
        }
        return menu_
      })
      return new_
    })
  }

  return (
    <div className="BetaTest">
      <div ref={ref} id="BetaTest_Content" className={`${modalVisible ? 'visible' : 'invisible'}`} >
        <div className="title">
          <span>{Resources.get("BetaTest","title","label")}</span>
        </div>
        <div className="hint">
          <div className="betaIcon">
            <Icon name="construction" />
          </div>
          <div className="hint__text">
            <span>{Resources.get("BetaTest","description","label")}</span>
          </div>
        </div>
        <div className="items">
          {
            betaMenus.map((item) => {
              return item.visible &&
                <label className="items__item" key={`item_${item.title}`}>
                  <p>{item.title}</p>
                  <Form.Check
                    key={`itemToggle_${item.title}`}
                    checked={item.enabled}
                    type="switch"
                    id={`itemToggle_${item.title}`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(event) => {
                      handleUpdateItem(item.title)
                    }}
                  />
                </label>
            })
          }
        </div >
      </div>
      <Modal id="BetaTest_Modal" show={alertVisible} animation={false}>
        <Modal.Header>
          <div className="betaIcon">
            <Icon name="construction" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Modal.Title>{Resources.get("BetaTest", "modalTitle", "label")}</Modal.Title>
          <p className="subtitle">{Resources.get("BetaTest", "modalDescription", "label")}</p>
          <div className="hint">
            {Resources.get("BetaTest", "modalHint", "label")}
            (<div className="iconHint">
              <Icon name="construction" />
            </div>)
            {Resources.get("BetaTest", "modalTogglePosition", "label")}
          </div>
        </Modal.Body>
        <Modal.Footer className='text-center'>
          <Button className='confirmBtn confirm' onClick={onConfirm} variant="main-action">
            {Resources.get("BetaTest", "modalBtnActivate", "label")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};