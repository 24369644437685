import AppRoutes from "AppRoutes.json";
import { DynamicComponents } from "components/DynamicComponents/DynamicComponents";
import React from "react";
import Div100vh from 'react-div-100vh';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { HeaderSearchProvider } from "./context/HeaderSearchProvider";
import { LayoutProvider } from "./context/LayoutContext";
import { StateProvider } from "./context/StateProvider";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { SidebarProvider } from "context/SidebarContext";

const ErrorPage = React.lazy(() => import("./pages/error/ErrorPage"));
const AboutModal = React.lazy(() => import("./components/modals/about/AboutModal"));

export const App = () => {
  return (
    <Div100vh>
      <div className="App">
        <LayoutProvider>
          <StateProvider>
          <SidebarProvider>
            <HeaderSearchProvider>
              <ToastContainer containerId={"AppToast"} className="AppToast" />
              <React.Suspense fallback={<span></span>}>
                <Router>
                  <Switch>
                    {AppRoutes.unrestricted.map((r, i) => <PublicRoute key={`public_route_${i}`} restricted={false} path={r.path} component={DynamicComponents.GetComponent(r.component) as React.ComponentType} />)}
                    {AppRoutes.restricted.map((r, i) => <PrivateRoute key={`private_route_${i}`} path={r.path} component={DynamicComponents.GetComponent(r.component) as React.ComponentType} exact showLeftSidebar={true} metadata={r.props} isGenericRoute={r.component === "GenericList"} beta={r.beta} />)}
                    <PublicRoute restricted={false} path="/*" component={ErrorPage} />
                  </Switch>
                </Router>
                <AboutModal />
              </React.Suspense>
            </HeaderSearchProvider>
            </SidebarProvider>
          </StateProvider>
        </LayoutProvider>
      </div>
    </Div100vh>
  );
};
