import { QueryDatasourceArgumentsFilter } from "@spring-global/spring-datasources/lib/src/Types";
import AppRoutes from "AppRoutes.json";
import { ErrorHandler } from "components/ErrorHandler/ErrorHandler";
import { FilterValue, GenericFilterElement, GenericFilters } from "components/GenericFilters/GenericFilter";
import { IStatusFilterProps } from "components/GenericFilters/StatusFilter/StatusFilter";
import { ITextFilterProps } from "components/GenericFilters/TextFilter/TextFilter";
import GenericListFilter from "components/GenericListFilter/GenericListFilter";
import { Header } from "components/Header/Header";
import { Icon } from "components/Icon/Icon";
import { LoadingData } from "components/LoadingData/LoadingData";
import { ISpringColumnDescription } from "components/SpringGrid/SpringGrid";
import { SpringGridResizable, SpringGridResizableOptions } from "components/SpringGridResizable/SpringGridResizable";
import { DateHelper } from "helpers/DateHelper";
import { useDatasourceDefinitionQuery } from "hooks/Datasources/useDatasourceDefinitionQuery";
import useAccessToken from "hooks/useAccessToken";
import { HeaderAction, HeaderSecondaryAction } from "models/actions/Header";
import { useEffect, useState } from "react";
import { ColumnDescription, TableChangeState, TableChangeType } from "react-bootstrap-table-next";
import { RangeModifier } from "react-day-picker";
import { match, useHistory } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useDatasourceDataQuery } from "../../hooks/Datasources/useDatasourceDataQuery";
import "./GenericList.scss";
import { GenericListFormatter, GenericListFormatterArgs } from "./GenericListFormatter";


export interface IGenericListHeaderAction {
    name: string;
    url: string;
    callback?: () => void;
}
interface IGenericListInfo {
    name: string;
    datasource: string;
    headerAction: IGenericListHeaderAction[];
}
interface IGenericListProps {
    listInfo: IGenericListInfo;
    match: match;
    beta?: boolean;
    reloadDeps?: any;
}

interface IGenericListMetadata {
    columns?: ColumnDescription[];
    clientSidePagination?: boolean;
    keyField?: string;
    filters?: GenericFilters;
}

const GenericList = (props: IGenericListProps) => {

    const history = useHistory();
    const [gridOptions, setGridOptions] = useState<SpringGridResizableOptions>(null!);
    const [top, setTop] = useState(10);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [dataLen, setDataLen] = useState<number>(0);
    const [columns, setColumns] = useState<ISpringColumnDescription[]>([]);
    const [keyField, setKeyField] = useState<string>("");
    const [isReloading, setReloading] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<GenericFilterElement[]>([])
    const [queryFilters, setQueryFilters] = useState<QueryDatasourceArgumentsFilter[]>([])
    const [headerSecondaryActions, setHeaderSecondaryActions] = useState<HeaderSecondaryAction[]>([]);
    const { hasAccessLevel } = useAccessToken();
    const datasourceDefQuery = useDatasourceDefinitionQuery(props.listInfo.datasource, 60);//TODO: create a configuration to define the cache value for fetching datasources definitions.

    const { data: datasourceDataQuery, isSuccess, isLoading: isLoadingData, isError, error, refetch } = useDatasourceDataQuery(datasourceDefQuery.data, top, skip, queryFilters);
    const metadata: IGenericListMetadata = datasourceDefQuery.data?.metadata;

    useEffect(() => {
        document.documentElement.style.setProperty("--filter-visible", "0");
    }, [])

    const toggleFilterPanel = () => {
        document.documentElement.style.setProperty("--filter-visible", isFilterPanelOpen ? "0" : "1");
        setIsFilterPanelOpen(!isFilterPanelOpen);
    };

    const handleFilterChange = (filterValue: FilterValue, filterId: string) => {
        const tmpFilters = filters;
        tmpFilters.find(x => x.id === filterId)!.value = filterValue
        setFilters(tmpFilters)
        const newFilters: QueryDatasourceArgumentsFilter[] = filters.reduce((acc: QueryDatasourceArgumentsFilter[], curr: GenericFilterElement) => {
            if (curr.enabled !== false && curr.value) {
                switch (curr.type) {
                    case "daterange":
                        if (Array.isArray(curr.value.field)) {
                            acc.push({
                                name: curr.value.field[0],
                                operator: "eq",
                                value: DateHelper.format((curr.value.value as RangeModifier).from!, "yyyy-LL-dd")
                            });
                            acc.push({
                                name: curr.value.field[1],
                                operator: "eq",
                                value: DateHelper.format((curr.value.value as RangeModifier).to!, "yyyy-LL-dd")
                            });
                        } else
                            acc.push({
                                name: curr.value.field,
                                operator: "eq",
                                value: curr.value.value
                            });
                        break;
                    case "multiselect":
                        acc.push({
                            name: curr.value.field as string,
                            operator: "in",
                            value: curr.value.value
                        });
                        break;
                    case "status":
                        acc.push({
                            name: (curr.properties as IStatusFilterProps).applyTo,
                            operator: "eq",
                            value: curr.value
                        });
                        break;
                    case "text":
                        if (curr.value) {
                            acc.push({
                                name: (curr.properties as ITextFilterProps).applyTo,
                                operator: "eq",
                                value: curr.value
                            });
                        }
                        break;
                    default:
                        acc.push({
                            name: curr.value.field as string,
                            operator: "eq",
                            value: curr.value.value
                        });
                        break;
                }
            }
            return acc;
        }, []);
        debouncedFilterChange(newFilters);
    }

    const debouncedFilterChange = useDebouncedCallback((newFilters) => {
        setQueryFilters(newFilters);
    }, 500);

    useEffect(() => {
        if (!isLoading) refetch();
    }, [props.reloadDeps])

    useEffect(() => {
        if (!isSuccess) return;

        const gridData = (datasourceDataQuery as any).value || datasourceDataQuery;

        let cols: ColumnDescription[] = (metadata?.columns || []).map(column => {
            const format: GenericListFormatterArgs = (column as any).format;
            return {
                dataField: column.dataField,
                text: column.text,
                hidden: column.hidden,
                isDummyField: column.isDummyField,
                classes: column.classes,
                headerClasses: column.headerClasses,
                style: column.style,
                sort: column.sort,
                searchable: column.searchable,
                align: column.align,
                headerStyle: column.headerStyle,
                tooltipDataField: column.tooltipDataField,
                headerAlign: column.headerAlign,
                width: column.width,
                footer: column.footer,
                footerClasses: column.footerClasses,
                footerStyle: column.footerStyle,
                footerTitle: column.footerTitle,
                footerEvents: column.footerEvents,
                footerAlign: column.footerAlign,
                formatter: GenericListFormatter.getFormatter(format),
            }
        });

        if (cols.length === 0) {
            cols = Object.keys(gridData[0] || {}).map((k) => {
                const col: ISpringColumnDescription = {
                    dataField: k,
                    text: k
                };
                return col;
            });
        };

        if (cols?.length > 0) {
            setKeyField(cols[0].dataField);
            setColumns(cols);
        }
        setData(gridData);
        setDataLen((datasourceDataQuery as any)["odata.count"] || datasourceDataQuery.length);
        setReloading(false);

    }, [datasourceDataQuery, isSuccess, metadata?.columns]);

    useEffect(() => {
        if (!keyField) return;
        if (columns.length === 0) return;

        setGridOptions({
            localStorageTableTag: "CommandCenterGenericList",
            keyField: metadata?.keyField || keyField,
            columns: columns,
            remote: !metadata?.clientSidePagination,
            onTableChange: (type: TableChangeType, newState: TableChangeState<any>) => {
                if (metadata?.clientSidePagination) return;
                if (type === "pagination") {
                    setPage(newState.page);
                    setReloading(true);
                }
            },
            paginationOptions: {
                totalSize: dataLen || 0,
                hideSizePerPage: false,
                sizePerPage: metadata?.clientSidePagination ? 10 : top,
                showTotal: true,
                page: page,
                onPageChange(page, sizePerPage) {
                    if (metadata?.clientSidePagination) return;
                    setSkip((page - 1) * sizePerPage);
                    setReloading(true);

                },
                onSizePerPageChange(page) {
                    if (metadata?.clientSidePagination) return;
                    setTop(page);
                    setReloading(true);
                }
            },
            data: data,
            loading: isReloading,
            classes: "genericList"
        });
        setLoading(false);
    }, [columns, data, dataLen, isReloading, keyField, page, top, metadata?.clientSidePagination, metadata?.keyField]);

    useEffect(() => {
        setHasErrors(datasourceDefQuery.isError || isError);
    }, [datasourceDefQuery.isError, isError]);

    useEffect(() => {
        setHasErrors(datasourceDefQuery.isError || isError);
    }, [datasourceDefQuery.isError, isError]);

    useEffect(() => {
        setFilters(metadata?.filters?.filters || []);
        const filterCount = metadata?.filters?.filters?.length || 0;
        const defaultVisibility = filterCount > 0 && metadata?.filters?.defaultVisibility || false;
        setIsFilterPanelOpen(defaultVisibility);
    }, [metadata?.filters]);

    useEffect(() => {
        if (metadata && metadata.filters && metadata.filters.filters?.length > 0) {
            setHeaderSecondaryActions([{
                isToggle: true,
                element: (
                    <Icon
                        name="funnel"
                        onClick={() => toggleFilterPanel()}
                        className={`${isFilterPanelOpen ? "active" : ""}`}
                    />)
            }]);
        }
    }, [metadata, isFilterPanelOpen]);

    const headerActions: HeaderAction[] = props.listInfo.headerAction.map(a => {
        if (a.callback) {
            return { name: a.name, callback: a.callback };
        } else {
            const route = AppRoutes.restricted.find(r => r.path === a.url);
            if (!route) return null!;
            if (route.accessLevel) {
                if (!hasAccessLevel(route.accessLevel)) return null!;
            }
            return { name: a.name, callback: () => history.push(a.url) };
        }
    }).filter(a => !!a);

    return (
        <div className="GenericListBody">
            <Header actions={headerActions} title={props.listInfo.name} secondaryActions={headerSecondaryActions} beta={props.beta} />
            {hasErrors
                ?
                <ErrorHandler error={datasourceDefQuery.error || error as any} />
                :
                <div className="GenericList">{isLoading ? <LoadingData /> :
                    <div className="GenericList__container">
                        <GenericListFilter isFilterPanelOpen={isFilterPanelOpen} filters={filters} displayCount={isLoadingData ? '...' : `${data.length || '0'}`} totalCount={isLoadingData ? '...' : `${dataLen || '0'}`} onFiltersChange={handleFilterChange} />
                        <div className="GenericList__content">
                            {isLoadingData ? <LoadingData />
                                :
                                <SpringGridResizable {...gridOptions} />
                            }
                        </div>
                    </div>
                }</div>
            }
        </div>
    );

};

export default GenericList;
