import { useContext } from "react";
import { LayoutContext } from "../../context/LayoutContext";

/**
 * Return the showModal state and the toggle function.
 * @returns The showModal state and the toggle function
 */
export const useAboutModal = () => {
  const context = useContext(LayoutContext);
  if (!context) throw Error('Not inside layout context');
  return {
    showAboutModal: context.state.showAboutModal,
    toggleAboutModal: context.toggleAboutModal
  };
};
