import { Datasource } from "@spring-global/spring-datasources/lib/src/Types";
import axios from "axios";
import { Endpoints } from "config/endpoints";
import { useQuery } from "react-query";

export const useDatasourceDefinitionQuery = (datasourceID: string, cacheTime: number = 60) => useQuery<Datasource>(
    [`useDatasourceDefinitionQuery_${datasourceID}`, datasourceID], async () => {
        try {
            const workspaceUrl = Endpoints.getEndpoint("REACT_APP_WORKSPACE_ENDPOINT");
            const url = `${workspaceUrl}datasource/id/${datasourceID}`;
            const { data } = await axios.get(url, { headers: { 'Cache-Control': `min-fresh=${cacheTime}` } })
            return data[0];
        } catch (err) {
            console.error(`error executing useDatasourceDefinitionQuery`, err);
            throw err;
        }
    },
    {
        cacheTime: Math.max(cacheTime, 10),
        retry: 0
    }
);