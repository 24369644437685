import React from 'react';
import './CellTemplateDualText.scss';

export type CellTemplateDualTextProps = {
    text: string;
    subText: string;
    replaceTokens?: string[];
    row: any;
};

export const CellTemplateDualText = (props: CellTemplateDualTextProps) => {

    let text: string = props.text;
    let subText: string = props.subText;

    try {
        if (props.replaceTokens) {
            const len: number = props.replaceTokens.length!;
            let i: number = 0;
            while (i < len) {
                text = text.replaceAll(`{${i}}`, props.row[props.replaceTokens[i]]);
                subText = subText.replaceAll(`{${i}}`, props.row[props.replaceTokens[i]]);
                i++;
            }
        }
    } catch (e) {
        console.error(e);
    }

    return (
        <div className="CellTemplateDualText">
            <div>{text}</div>
            <div>{subText}</div>
        </div>
    );
}

export default React.memo(CellTemplateDualText);