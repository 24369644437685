import { useCallback, useEffect, useState } from "react";

interface IResizeColumn {
    isResizing: boolean,
    active?: {
        index: number,
        offset: number,
        width: number;
    }
}
export interface IColumnResizableOptions {
    isResizing: boolean;
    columnsWidths: number[];
    setMouseResizeColumn: (e: any, index: number) => void;
}

export interface IColumnResizableProps {
    localStorageTableTag?: string,
    columns: any[],
    mincolumwidth?: number
};

type STORAGE_STATUS = "INIT" | "IDLE" | "SAVE";

export const useResizableColumn = (props: IColumnResizableProps) => {
    const RESIZE_LOCALSTORAGE_TAG = "localPreferences";
    const { columns, localStorageTableTag, mincolumwidth = 50 } = props;
    const [columnsWidths, setColumWidths] = useState<number[]>(columns.map(m => mincolumwidth));
    const [resize, setResize] = useState<IResizeColumn>({ isResizing: false });
    const [storageStatus, setStorageStatus] = useState<STORAGE_STATUS>("INIT");

    useEffect(() => {
        // get columns from localstorage
        if (storageStatus == "INIT" && localStorageTableTag) {
            const storage = JSON.parse(localStorage.getItem(RESIZE_LOCALSTORAGE_TAG) || '{}');
            const localcolums = storage["table:" + localStorageTableTag];
            if (localcolums
                && Array.isArray(localcolums)
                && localcolums.length === columnsWidths.length) {
                setColumWidths(localcolums);
            }
            setStorageStatus("IDLE");
        }
    }, [columns]);

    useEffect(() => {
        //save columns in localstored
        if (storageStatus == "SAVE" && localStorageTableTag) {
            const storage = JSON.parse(localStorage.getItem(RESIZE_LOCALSTORAGE_TAG) || '{}');
            storage["table:" + localStorageTableTag] = columnsWidths;
            localStorage.setItem(RESIZE_LOCALSTORAGE_TAG, JSON.stringify(storage));
            setStorageStatus("IDLE");
        }
    }, [storageStatus]);

    const calculateWidths = useCallback((e) => {
        const gridcolumns = columnsWidths.map((col, i) => {
            if (resize.active && resize.active.index === i) {
                const { offset, width } = resize.active;
                const newWidth = (e.clientX - offset) + width;
                return newWidth >= mincolumwidth ? newWidth : mincolumwidth;
            }
            return col;
        });
        setColumWidths(gridcolumns);
    }, [resize, columns]);

    const stopResizing = useCallback(() => {
        setResize({ isResizing: false })
        setStorageStatus("SAVE");
    }, [resize]);

    const startResizing = (e: any, index: number) => {
        setResize({
            isResizing: true,
            active: {
                index,
                offset: e.clientX,
                width: e.currentTarget.parentElement?.clientWidth || mincolumwidth
            }
        });
    }

    const isIndexResizing = (index: number) => {
        return resize.active && resize.isResizing && resize.active.index === index;
    }


    return {
        status: {
            isResizing: resize.isResizing,
            storageStatus,
            columnsWidths
        },
        events: {
            isIndexResizing,
            startResizing,
            stopResizing,
            calculateWidths
        }
    }
}