import { AppSession } from "../models/AppSession";
import {Identity} from "../models/Identity";

export type Action = { type: "SET_IDENTITY"; identity: Partial<Identity> };

export const ActionTypes = {
  SET_IDENTITY: "SET_IDENTITY",
};

export const appReducer = (state: AppSession, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_IDENTITY:
      return {
        ...state,
        identity: action.identity,
      };
    default:
      return state;
  }
};
