import React, { useEffect, useRef, useState } from "react";
import { Icon } from "../Icon/Icon";
import "./DetailList.scss";
import { ActionButtonProps } from "../ActionButton/ActionButton";

export type ListItem<U> = {
  label: string;
  value: U;
  action?: string;
}

export interface detailAction{
  id: string;
  action: (props?: any) => any;
}

type Props = {
  id?: string;
  name?: string;
  className?: string;
  items?: ListItem<any>[];
  value?: any,
  disabled?: boolean;
  required?: boolean;
  CustomButton?: (props: ActionButtonProps) => JSX.Element;
  customBtnClassName?: string;
  customBtnStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  actions: detailAction[];
};

export const DetailList = React.forwardRef((props: Props, _ref) => {
  const ref = useRef(null);
  const {
    id,
    className = "",
    items = [],
    disabled = false,
    CustomButton,
    customBtnClassName,
    customBtnStyle,
    style,
    actions
  } = props;

  const [ expanded, setExpanded ] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref?.current as any).contains(event.target as Element)) {
        setExpanded(false);
      }
    }
    const removeWindowEventListener = () => document.addEventListener("mousedown", handleClickOutside);
    const addWindowEventListener = () => document.addEventListener("mousedown", handleClickOutside);

    (expanded ? addWindowEventListener : removeWindowEventListener)();
    return () => removeWindowEventListener();
  }, [ ref, expanded ]);

  const handleExpand = () => {
    if (disabled) return;
    setExpanded(!expanded);
  };

  const handleSelectItem = (item: ListItem<any>) => {
    setExpanded(false);
    if(item.action !== undefined)
      actions.find(x=>x.id === item.action)?.action();
  };

  return (
    <div id={ id } ref={ ref }
         className={ `Detaillist ${ className } ${ disabled ? 'Detaillist--disabled' : '' } ${ expanded ? 'Detaillist--expanded' : '' }` }
         style={ style }>
      {
        CustomButton ?
          <CustomButton
            id={ id }
            label={ 'Export' }
            className={ customBtnClassName ? customBtnClassName : 'Detaillist__expand' }
            onClick={ handleExpand }
            style={ customBtnStyle }
          />
          :
          <div id={ id } className="Detaillist__expand">
            <Icon
              id={ `${ id }-svg` }
              name="scoring"
              onClick={ handleExpand }
            />
          </div>
      }

      <div className="Detaillist__container">
        <div
          style={ { display: expanded ? "block" : "none" } }
          className={ "Detaillist__items" }
        >
          { items.map(item => (
            <div
              id={ `${ id }-opt` }
              key={ `detaillist-${ JSON.stringify(item.value) }` }
              onClick={ () => handleSelectItem(item) }
              className={ `Detaillist__item` }
            >
              <span id={ `${ id }-opt` } className={ `label text-ellipsis` } title={ item.label }>{ item.label }</span>
            </div>
          )) }
        </div>
      </div>
    </div>
  );
});
