import React, { CSSProperties } from "react";
import { useRef } from "react";
import icons from "./../../assets/icons/svg/icons.svg";

import './Icon.scss';

type Props = {
  id?: string;
  name?: string;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: any;
  tooltip?: string;
  ref?: any;
  testId?: string;
};

const _Icon = (props: Props) => {
  const {
    name = undefined,
    onClick = () => {
    },
    disabled = false,
    className = "",
    id = undefined,
    tooltip = '',
    ref = null,
    style = {},
    testId
  } = props;

  const _ref = useRef<SVGSVGElement>(ref);

  return (
    <svg
      ref={ _ref }
      id={ id }
      name={ name }
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      onClick={ onClick }
      className={ `Icon ${ className } ${ disabled ? 'Icon--disabled' : '' }` }
      width="100%"
      height="100%"
      style={style}
      data-testid={testId}
    >
      { tooltip && <title>{ tooltip }</title> }
      <use id={ id } xlinkHref={ `${ icons }#${ name }` }/>
    </svg>
  );
};

export const Icon = React.memo(_Icon);
