import { RestrictedComponent } from "components/RestrictedComponent/RestrictedComponent";
import { printFile } from "helpers/UtilHelper";
import JSZip from 'jszip';
import PDFMerger from 'pdf-merger-js/browser';
import { useCallback } from "react";
import { Resources } from "../../resources/Resources";
import { OrdersService } from "../../services/OrdersService";
import { ActionButton } from "../ActionButton/ActionButton";
import { DetailList } from "../DetailList/DetailList";
import './SelectActions.scss';

export interface SelectActionsOptions {
  handleClear: () => void;
  selectCount: number;
  items: any[];
}


const SelectActions = (SelectActionsOptions: SelectActionsOptions) => {
  const { handleClear, selectCount = 0, items } = SelectActionsOptions;
  const onClearClick = useCallback((e) => {
    e.preventDefault();
    handleClear();
  }, [handleClear]);

  const handleExportMultipleFiles = async () => {
    const fileNames = items.join('_');
    const files = await OrdersService.getMultiplePDF(fileNames);
    for (const pdf of files) {
      const downloadLink = document.createElement("a");
      downloadLink.href = pdf.fileData;
      downloadLink.download = `${pdf.id}.pdf`;
      downloadLink.click();
    }
  }

  const handleExportSingleFile = async () => {
    const zip = new JSZip();
    const fileNames = items.join('_');
    const files = await OrdersService.getMultiplePDF(fileNames);
    for (const pdf of files) {
      zip.file(`${pdf.id}.pdf`, pdf.fileData.split(',')[1], { base64: true });
    }
    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(content);
        downloadLink.download = "export.zip";
        downloadLink.click();
      });
  }

  const handlePrint = async () => {
    const fileNames = items.join('_');
    const files = await OrdersService.getMultiplePDF(fileNames);
    const merger = new PDFMerger();
    for (const pdf of files) {
      const pdfBuffer = Buffer.from(pdf.fileData.replace('data:application/pdf;base64,', ''), 'base64');
      await merger.add(pdfBuffer);
    }

    const blob = await merger.saveAsBlob();
    printFile(blob);

  }

  return (
    <div className={`SpringGridSelectAction ${selectCount > 0 ? '' : 'SpringGridSelectActionHide'}`}>
      <div className={'SelectActionSections SelectActionLeftSection'}>
        <span className={'SelectActionItem SelectionCounter'}>{`${selectCount} Selected`}</span>
        <a className={'SelectActionItem SelectionCleaner'} onClick={onClearClick} href="#">Clear All</a>
      </div>
      <div className={'SelectActionSections SelectActionRightSection'}>
        <RestrictedComponent accessLevel='CC.Orders.Print'>
          <ActionButton
            label={'Print'}
            className={'SelectActionButton'}
            onClick={handlePrint}
          />
        </RestrictedComponent>
        <RestrictedComponent accessLevel='CC.Orders.Export'>
          <DetailList
            CustomButton={ActionButton}
            customBtnClassName={'SelectActionButton'}
            className={'SelectActionButton'}
            id={"exportExpand"}
            style={{ marginTop: "1rem" }}
            items={[
              {
                label: Resources.get("OrderExport", "singleFile", "label"),
                value: Resources.get("OrderExport", "singleFile", "label"),
                action: "single"
              },
              {
                label: Resources.get("OrderExport", "multipleFiles", "label"),
                value: Resources.get("OrderExport", "multipleFiles", "label"),
                action: "multiple"
              }
            ]}
            actions={[
              { id: "single", action: () => handleExportSingleFile() },
              { id: "multiple", action: () => handleExportMultipleFiles() }
            ]}
          />
        </RestrictedComponent>
      </div>
    </div>
  );
}

export default SelectActions;
