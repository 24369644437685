import { SpringLogger } from "@spring-global/spring-logger-react";
import React from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";
import { Resources } from "../../resources/Resources";

export enum ErrorHandlerType {
  ServerError = 1,
  Forbidden = 2,
  Validation = 3,
}

// @TODO Check how to take this Messages from the Resources
export const ErrorHandlerMessage = {
  ServerError: Resources.get("ErrorHandler", "serverError", "label"),
  Forbidden: Resources.get("ErrorHandler", "forbidden", "label")
}

export interface SpringError extends Error {
  type?: ErrorHandlerType;
}

export interface ErrorHandlerOptions {
  error: SpringError;
  variant?: Variant;
}

export const undefinedError: SpringError = {
  name: "",
  message: "",
  type: ErrorHandlerType.ServerError,
};

export const ErrorHandler = ({
  error,
  variant = "danger",
}: ErrorHandlerOptions) => {
  if (!error.type) {
    error.type = ErrorHandlerType.ServerError;
  }

  if (error.type !== ErrorHandlerType.Validation) {
    SpringLogger.info(error);
  }

  return (
    <div className="ErrorHandler">
      <Alert key="errorHandlerMessage" variant={variant}>
        {error.type === ErrorHandlerType.ServerError &&
          ErrorHandlerMessage.ServerError}
        {error.type === ErrorHandlerType.Forbidden &&
          ErrorHandlerMessage.Forbidden}
        {error.type === ErrorHandlerType.Validation && error.message}
      </Alert>
    </div>
  );
};
