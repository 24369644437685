import { ColorBadge } from "components/ColorBadge/ColorBadge";
import { DynamicComponents } from "components/DynamicComponents/DynamicComponents";
import { DateHelper } from "helpers/DateHelper";
import React, { ReactElement } from "react";
import { ColumnFormatter } from "react-bootstrap-table-next";
import { Link } from "react-router-dom";

export interface GenericListFormatterArgs {
    type?: "datetime" | "date" | "number" | "currency" | "string" | "statusbadge" | "link" | "component";
    dateTimeFormat?: string;
    decimalPlaces?: number;
    statusFormat?: {
        textField?: string;
        semanticValueField?: string;
    },
    link?: {
        text?: string;
        textField?: string;
        url: string;
        replaceTokens?: string[];
        target?: "_blank" | "_self" | "_parent" | "_top" | string;
    },
    component?: {
        name: string;
        properties?: { [property: string]: any };
        innerText?: string;
    }
}

export class GenericListFormatter {

    private static formatLinkUrl(formatArgs: GenericListFormatterArgs, row: any): string {
        const replaceTokens: string[] = formatArgs.link?.replaceTokens || [];
        const len = replaceTokens.length;
        let url: string = formatArgs.link?.url || "";
        let i: number = 0;
        while (i < len) {
            url = url.replaceAll(`{${i}}`, row[replaceTokens[i]] || replaceTokens[i]);
            i++;
        }
        return url;
    }

    private static loadComponent(formatArgs: GenericListFormatterArgs, row: any): ReactElement {
        const DynamicComponent = DynamicComponents.GetComponent(formatArgs?.component?.name!);
        const props = { ...formatArgs.component?.properties, row: row };
        return <DynamicComponent {...props} >{formatArgs?.component?.innerText} </DynamicComponent>;
    }

    public static getFormatter<R, E = any, C = any>(formatArgs: GenericListFormatterArgs): ColumnFormatter<R, E, C> | undefined {
        if (!formatArgs || !formatArgs.type) return undefined;

        switch (formatArgs.type) {
            case "datetime":
            case "date":
                return (cell: any, row, index) => (
                    <div>{cell ? DateHelper.format(DateHelper.date(new Date(cell)), formatArgs.dateTimeFormat || "MM/dd/yyyy") : ''}</div>
                );
            case "number":
                return (cell: any, row, index) => (
                    <div>{Number(cell).toFixed(formatArgs.decimalPlaces == null ? 2 : formatArgs.decimalPlaces)}</div>
                );
            case "currency":
                return (cell: any, row, index) => (
                    <div>{Number(cell).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                );
            case "statusbadge":
                return (cell: any, row: any) => (
                    <ColorBadge text={row[formatArgs.statusFormat?.textField!]} semanticValue={row[formatArgs.statusFormat?.semanticValueField!]} />
                );
            case "link":
                return (cell: any, row: any) => (
                    <Link to={this.formatLinkUrl(formatArgs, row)} target={formatArgs.link?.target || "_self"} >{formatArgs.link?.text || row[formatArgs.link?.textField!]}</Link>
                );
            case "component":
                return (cell: any, row: any) => (
                    <React.Fragment>
                        <div>{this.loadComponent(formatArgs, row)}</div>
                    </React.Fragment>
                );
            default:
                return undefined;
        }
    }

}