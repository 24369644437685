import IconButton from "@mui/material/IconButton"
import { Icon } from "components/Icon/Icon";
import React, { useContext } from "react";

export interface ActionIcon {
    icon: string,
    action: string
}

export interface CellTemplateActionIconsProps {
    items: ActionIcon[],
    row: any;
}

const CellTemplateActionIcons = (props: CellTemplateActionIconsProps) => {
    const { items, row } = props;
    const { onActionClick, isVisible } = useActionCallback();

    const handleActionClick = (action: string) => {
        onActionClick(action, row);
    }

    return <div className="Actions">
        {items.map((m, i) => {
            if (isVisible(m.icon, row)) {
                return <IconButton
                    key={`action-icon-${i}`}
                    className="icon-button-row-action"
                    onClick={() => handleActionClick(m.action)}
                    size="large">
                    <Icon name={m.icon} />
                </IconButton>
            }
        })}
    </div >
}

export const ActionIconsContext = React.createContext({
    onActionClick: (action: string, row: any) => { },
    isVisible: (icon: string, row: any): boolean => true
});

export const useActionCallback = () => {
    const context = useContext(ActionIconsContext);
    if (context === undefined) {
        throw new Error("useActionIcons must be used within a ActionIconsContext.Provider");
    }
    return context;
}

export default React.memo(CellTemplateActionIcons);