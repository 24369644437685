import { CalendarDayPicker } from "components/CalendarDayPicker/CalendarDayPicker";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { RangeModifier } from "react-day-picker/types/Modifiers";
import "react-day-picker/lib/style.css";
import "./DateRangeFilter.scss";
import { useEffect, useState } from "react";
import { DateHelper } from "helpers/DateHelper";
import { FilterValue } from "../GenericFilter";

const today: Date = DateHelper.now();
const start: Date = DateHelper.startOf('day', DateHelper.startOf('week', today));
const end: Date = DateHelper.endOf('day', DateHelper.endOf('week', today));
const DEFAULT_CURRENT_WEEK: RangeModifier = {from: start, to: end};

export interface IDateRangeFilterField {
    displayName: string;
    fieldName: string;
    rangeParams?: {
        from: string,
        to: string
    }
}

export interface IDateRangeFilter {
    fields?: IDateRangeFilterField[];
    defaultFieldName: string;
}

export interface IDateRangeProps{
    dateRange: IDateRangeFilter;
    filterId: string;
    value?: RangeModifier;
    onFilterChange: (filterValue: FilterValue, filterId: string) => any;
}

const DateRangeFilter = (props: IDateRangeProps) => {
    const { 
        dateRange,
        filterId,
        value = DEFAULT_CURRENT_WEEK,
        onFilterChange = (filterValue: FilterValue, filterId: string) => { }
    } = props;
    const [selectedRange, setSelectedRange] = useState<RangeModifier | undefined >(value);
    const [selectedField, setSelectedField] = useState<string>(dateRange.defaultFieldName)

    const onChangeSelectedField = (field: string) => {
        setSelectedField(field)
        onChangeFilterDate(selectedRange!)
    }

    const onChangeFilterDate = (selectedDays: Date | RangeModifier) => {
        if ("from" in selectedDays && "to" in selectedDays &&  selectedDays.from && selectedDays.to)
            setSelectedRange({from: selectedDays.from, to: selectedDays.to});
        else
            setSelectedRange({from: selectedDays as Date, to: DateHelper.now()});
    };

    useEffect(()=>{
        dateRange.fields?.map(field=>{
            if(!field.rangeParams){
                onFilterChange({
                    value: field.fieldName == selectedField ? selectedRange : undefined,
                    field: field.fieldName
                }, filterId)
            }else{
                onFilterChange({
                    value: selectedRange,
                    field: [field.rangeParams.from, field.rangeParams.to]
                }, filterId)
            }
        });
    },[selectedRange])

    return (
        <div className="DateRangeFilter">
            {dateRange.fields && dateRange.fields.length > 1 &&
                <ButtonGroup toggle size='lg' className="ButtonGroup">
                    {dateRange.fields.map((field, index) =>
                        <ToggleButton
                            key={`toggleButton_${index}`}
                            id={`toggleButton_${index}`}
                            value={field.fieldName}
                            type={'checkbox'}
                            className={`type-date ${(field.fieldName === selectedField) ? 'type-active' : 'type-inactive'}`}
                            onClick={()=>onChangeSelectedField(field.fieldName)}
                        >
                            {field.displayName}
                        </ToggleButton>
                    )}
                </ButtonGroup>}


            <CalendarDayPicker
                selectedRange={value}
                onDaySelect={onChangeFilterDate}
                showTodayButton={true}
                showThisMonthButton={true}
                showThisWeekButton={true}
            />
        </div >
    );
};

export default DateRangeFilter;
