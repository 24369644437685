import { TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FilterValue } from "../GenericFilter";
import "./TextFilter.scss";

export interface ITextFilterProps {
    filterId: string;
    value?: string;
    onFilterChange: (filterValue: FilterValue, filterId: string) => any;
    textLabel: string;
    applyTo: string;
}

export const TextFilter = forwardRef((props: ITextFilterProps, ref) => {

    const [text, setText] = useState<string>("");

    useImperativeHandle(ref, () => ({
        reset: () => setText("")
    }));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    };

    useEffect(() => {
        props.onFilterChange(text as any, props?.filterId!);
    }, [text]);

    return (
        <div className="TextFilter" >
            <TextField
                label={props.textLabel}
                variant="standard"
                type="search"
                size="small"
                value={text}
                onChange={handleInputChange}
            />
        </div >
    );
});
