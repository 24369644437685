import { setupWorker } from 'msw';

export async function setupMocks() {
  const FLAG = 'msw-cc-mocks-enabled'
  let enableMocking: string | null = 'false';
  if (process.env.NODE_ENV === 'development') {
    enableMocking = localStorage.getItem(FLAG);
  }

  const mockHandlers = (
    await Promise.all([
      import('./command-center').then(m => m.default),
      import('./forecast').then(m => m.default),
      import('./identity').then(m => m.default),
      import('./general').then(m => m.default),
    ])
  ).flat();

  if (process.env.NODE_ENV === 'development' && enableMocking) {
    const worker = setupWorker(...mockHandlers);

    (window as any).mocks = {
      start: async () => {
        await worker.start();
        localStorage.setItem(FLAG, 'true');
      },
      stop: () => {
        worker.stop();
        localStorage.setItem(FLAG, 'false');
      } 
    }

    if(enableMocking === 'true') worker.start(); else worker.stop();
  }
}

