import { ServiceConfig } from "./ServiceConfig";

const endPoints = require('./endpoints.json');

export class Endpoints {
    static getEndpoint(key: string): string {
        return `${ServiceConfig.gateway_base_url}` + endPoints[key];
    }
    static getWebsocketEndpoint(key: string): string {
        return `${ServiceConfig.websocket_base_url}` + endPoints[key];
    }
    static getLanguageEndpoint(): string {
        return `${ServiceConfig.language_base_url}`;
    }
}