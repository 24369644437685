import React from "react";
import './LoadingData.scss';
import { Spinner } from "react-bootstrap";

interface LoadingDataProps{
  title?: string;
  className?: string;
  animation?: "border" | "grow";
  size?: "sm";
}

export const LoadingData = (props: LoadingDataProps) => {

  const { 
    title,
    size,
    className = "",
    animation = "border"
  } = props;

  return (
    <div className="LoadingData">
      <div className="LoadingData__message">{title}</div>
      <Spinner
        className={`${className} spinner`}
        data-testid="loadingSpinner"
        animation={animation}
        size={size}
      />
    </div>
  );
}
