import { FormGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FilterValue } from "../GenericFilter";
import { Checkbox, FormControlLabel } from "@mui/material";
import "./MultiSelectFilter.scss";

export interface IMultiSelectFilter {
    fieldName: string;
    options: string[];    
}

export interface IMultiSelectProps{
    multiSelect: IMultiSelectFilter;
    filterId: string;
    value?: string[];
    onFilterChange: (filterValue: FilterValue, filterId: string) => any;
}

const MultiSelectFilter = (props: IMultiSelectProps) => {
    const { 
        multiSelect,
        filterId,
        onFilterChange = (filterValue: FilterValue, filterId: string) => { }
    } = props;

    const [selected, setSelected] = useState<string[]>([]);

    const onChangeFilter = (e: React.ChangeEvent<HTMLInputElement>, option: string) => {
        const indexTmp = selected.findIndex(x=>x === option);
        if(e.target.checked){
            if(indexTmp < 0)
                setSelected([...selected,option]);
        }else{
            if(selected.length === 1)
                setSelected([])
            else{
                const tmpSelected = selected;
                tmpSelected.splice(indexTmp, 1)
                setSelected([...tmpSelected]);
            }
        }
    };

    useEffect(()=>{
        onFilterChange({
            value: selected,
            field: multiSelect.fieldName
        },filterId)
    },[selected])

    return (
        <div className="MultiSelectFilter">
            <FormGroup className="MultiSelectFilter__option">
                {multiSelect.options.map((option: string)=>{
                    return <FormControlLabel key={`MultiSelectFilter__option__${option}`} control={<Checkbox onChange={(e)=>onChangeFilter(e, option)} />} label={option} />
                })
                }
            </FormGroup>
        </div >
    );
};

export default MultiSelectFilter;
