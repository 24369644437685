import React, { useMemo, useState } from "react";
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import  DayPicker, { DateUtils, RangeModifier } from "react-day-picker";
import { Resources } from "../../resources/Resources";
import "./CalendarDayPicker.scss"

const Button = React.lazy(() => import ("react-bootstrap/Button"));

const ButtonVariant = {
    ACTIVE: "main-action--clear",
    DEACTIVE: "secondary-action"
}

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const EVENTS_KEY_FORMAT = 'yyyyMMdd';

export type CalendarDayPickerProps = {
    showTodayButton?: boolean;
    showThisWeekButton?: boolean;
    showThisMonthButton?: boolean;
    highlightToday?: boolean;
    selectedDay?: Date;
    selectedRange?: RangeModifier;
    eventDates?: Date[];
    onDaySelect: (selectedDays: Date | RangeModifier) => void;
}

export const CalendarDayPicker = (props: CalendarDayPickerProps) => {
    const {
      showTodayButton = false,
      showThisWeekButton = false,
      showThisMonthButton = false,
      highlightToday = true,
      selectedDay = new Date(),
      selectedRange = null,
      eventDates = null,
      onDaySelect,
    } = props;

    const [todayBtnVariant, setTodayBtnVariant] = useState<string>(ButtonVariant.DEACTIVE);
    const [weekBtnVariant, setWeekBtnVariant] = useState<string>(ButtonVariant.DEACTIVE);
    const [monthBtnVariant, setMonthBtnVariant] = useState<string>(ButtonVariant.DEACTIVE);

    const isRangeMode = selectedRange !== null;

    const events: {[key: string]: boolean} = useMemo(() => {
        const eventsHash: {[key: string]: boolean} = {};
        if (eventDates && eventDates.length > 0) {
            eventDates.forEach((ev: Date) => {
                eventsHash[format(ev, EVENTS_KEY_FORMAT)] = true;
            })
        }
        return eventsHash;
    }, [eventDates]);

    return (
      <div className="CalendarDayPicker">
        <DayPicker
          showOutsideDays
          enableOutsideDaysClick
          className={isRangeMode ? "rangePicker" : undefined}
          selectedDays={isRangeMode ? selectedRange as RangeModifier : selectedDay}
          modifiers={isRangeMode ?
            {
              start: selectedRange?.from,
              end: selectedRange?.to,
              today: highlightToday ? new Date() : null
            } as any
            :
            {
              today: highlightToday ? new Date() : null
            }}
          weekdaysShort={WEEKDAYS_SHORT}
          onDayClick={(day) => {
            if (isRangeMode) {
              const range = DateUtils.addDayToRange(day, selectedRange as RangeModifier);
              if(!range.from && range.to) {
                range.from = range.to;
              }
              if(range.from && !range.to) {
                range.to = range.from
              }
              range.from = startOfDay(range.from as Date);
              range.to = endOfDay(range.to as Date);
              onDaySelect(range);
            } else {
              onDaySelect(startOfDay(day));
            }

            if (todayBtnVariant === ButtonVariant.ACTIVE) {
              setTodayBtnVariant(ButtonVariant.DEACTIVE);
            } else if (weekBtnVariant === ButtonVariant.ACTIVE) {
              setWeekBtnVariant(ButtonVariant.DEACTIVE);
            } else if (monthBtnVariant === ButtonVariant.ACTIVE) {
              setMonthBtnVariant(ButtonVariant.DEACTIVE);
            }
          }}
          renderDay={(day, modifiers) => {
            const date = day.getDate();
            return (
              <div className={'dayWithEventContainer'}>
                <div>{date}</div>
                {events[format(day, EVENTS_KEY_FORMAT)] &&
                  <div className={"dayWithEvent"} />
                }
              </div>)
          }}
        />
        <div>
          {showTodayButton &&
            <Button
              variant={todayBtnVariant}
              className="todayBtn"
              onClick={() => {
                const today = new Date();
                if(isRangeMode) {
                  onDaySelect({from: startOfDay(today), to: endOfDay(today)});
                } else {
                  onDaySelect(startOfDay(today));
                }
                if (todayBtnVariant === ButtonVariant.DEACTIVE) {
                  setTodayBtnVariant(ButtonVariant.ACTIVE);
                }
                if (weekBtnVariant === ButtonVariant.ACTIVE) {
                  setWeekBtnVariant(ButtonVariant.DEACTIVE);
                } else if (monthBtnVariant === ButtonVariant.ACTIVE) {
                  setMonthBtnVariant(ButtonVariant.DEACTIVE);
                }
              }}
            >{Resources.get("CalendarDayPicker", "todayBtn", "label")}</Button>
          }
          {showThisWeekButton &&
            <Button
              variant={weekBtnVariant}
              className="weekBtn"
              onClick={() => {
                const today = new Date();
                const start = startOfDay(startOfWeek(today));
                const end = endOfDay(endOfWeek(today));
                onDaySelect({from: start, to: end});
                if (weekBtnVariant === ButtonVariant.DEACTIVE) {
                  setWeekBtnVariant(ButtonVariant.ACTIVE);
                }
                if (todayBtnVariant === ButtonVariant.ACTIVE) {
                  setTodayBtnVariant(ButtonVariant.DEACTIVE);
                } else if (monthBtnVariant === ButtonVariant.ACTIVE) {
                  setMonthBtnVariant(ButtonVariant.DEACTIVE);
                }
              }}
            >
              {Resources.get("CalendarDayPicker", "thisWeekBtn", "label")}</Button>
          }
          {showThisMonthButton &&
            <Button
              variant={monthBtnVariant}
              className="monthBtn"
              onClick={() => {
                const today = new Date();
                const start = startOfDay(startOfMonth(today));
                const end = endOfDay(endOfMonth(today));
                onDaySelect({from: start, to: end});
                if (monthBtnVariant === ButtonVariant.DEACTIVE) {
                  setMonthBtnVariant(ButtonVariant.ACTIVE);
                }
                if (todayBtnVariant === ButtonVariant.ACTIVE) {
                  setTodayBtnVariant(ButtonVariant.DEACTIVE);
                } else if (weekBtnVariant === ButtonVariant.ACTIVE) {
                  setWeekBtnVariant(ButtonVariant.DEACTIVE);
                }
              }}
              >
              {Resources.get("CalendarDayPicker", "thisMonthBtn", "label")}
            </Button>
          }
        </div>
      </div>
    )
}
