if (process.env.NODE_ENV && process.env.NODE_ENV.trim() === 'local') {
  require("dotenv").config();
}
export class ServiceConfig {
  public static readonly gateway_base_url: string | undefined = process.env.REACT_APP_GATEWAY_BASE_URL;
  public static readonly language_base_url: string | undefined = process.env.REACT_APP_LANGUAGE_TOOLKIT;
  public static readonly spring_logger: string | undefined = process.env.REACT_APP_SPRING_LOGGER_SENTRY_DSN;
  public static readonly command_center_url: string | undefined = process.env.REACT_APP_COMMAND_CENTER_URL;
  public static readonly workspace_embedded_url: boolean | undefined = process.env.REACT_APP_EMBEDDED_WORKSPACE_URL === 'true';
  public static readonly websocket_base_url: string | undefined = process.env.REACT_APP_WEBSOCKET_BASE_URL;
}
