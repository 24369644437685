import { useCallback, useEffect, useMemo } from "react";
import { SpringGrid, SpringGridOptions } from "components/SpringGrid/SpringGrid";
import { useResizableColumn } from '../../hooks/useResizableColumn';

import "./SpringGridResizable.scss";
export interface SpringGridResizableOptions extends SpringGridOptions {
    localStorageTableTag: string
};

export const SpringGridResizable = (props: SpringGridResizableOptions) => {
    const { columns, localStorageTableTag } = props;
    const MIN_COLUM_WIDTH = 20;

    const { status, events } = useResizableColumn({
        columns,
        localStorageTableTag,
        mincolumwidth: MIN_COLUM_WIDTH
    });

    const stopResizing = useCallback(() => {
        events.stopResizing();
        removeListeners();
    }, [status.isResizing])

    const removeListeners = useCallback(() => {
        window.removeEventListener('mousemove', events.calculateWidths);
        window.removeEventListener('mouseup', stopResizing);
    }, [events.calculateWidths, stopResizing]);

    useEffect(() => {
        if (status.isResizing) {
            window.addEventListener('mousemove', events.calculateWidths);
            window.addEventListener('mouseup', stopResizing);
        }
        return () => {
            removeListeners();
        }
    }, [status.isResizing, events.startResizing, events.stopResizing, removeListeners]);

    const headerFormatterRezisable = (column: any, index: number, { sortElement }: any) => {
        const showResizeBar = events.isIndexResizing(index);
        const columWidth = status.columnsWidths[index];
        const width = columWidth >= MIN_COLUM_WIDTH ? columWidth : undefined;
        return (
            <div className="SpringGridResizable"  >
                <div style={{ width }}>
                    {column.text}
                    {sortElement}
                </div>
                <div className={`resizebar ${showResizeBar ? 'active' : ''} `}
                    onMouseDown={(e) => events.startResizing(e, index)}>
                </div >
            </div >
        );
    }

    const columsResizable = useMemo(() => {
        const defaultWidth = 100 / columns.length;
        return columns.map((item, index) => {
            const columnclass = events.isIndexResizing(index) ? "resizing" : undefined;
            const cursor = status.isResizing ? "col-resize" : undefined;
            return {
                ...item,
                classes: item.classes ?? columnclass,
                headerFormatter: headerFormatterRezisable,
                headerStyle: {
                    width: defaultWidth + "%",
                    cursor
                }
            }
        });
    }, [columns, status])

    return <SpringGrid {...props}
        columns={columsResizable}
    />
}