import { Datasource, QueryDatasourceArguments, QueryDatasourceArgumentsFilter } from "@spring-global/spring-datasources/lib/src/Types";
import axios from "axios";
import { Endpoints } from "config/endpoints";
import { useQuery } from "react-query";

export const useDatasourceDataQuery = (datasourceDef?: Datasource, top?: number, skip?: number, params?: QueryDatasourceArgumentsFilter[]) => useQuery<any[]>(
    [`useDatasourceDataQuery${datasourceDef?.id}_{${top || 0}_${skip || 0}}_${JSON.stringify(params)}`], async () => {
        try {
            const workspaceUrl = Endpoints.getEndpoint("REACT_APP_WORKSPACE_ENDPOINT");
            const url = `${workspaceUrl}datasource/data/${datasourceDef?.id}`;
            const clientSidePagination: boolean = datasourceDef?.metadata?.clientSidePagination;

            const queryParams: QueryDatasourceArguments = {
                top: clientSidePagination ? undefined : top,
                skip: clientSidePagination ? undefined : skip,
                inlinecount: clientSidePagination ? undefined : "allpages",
                params
            }
            const { data } = await axios.post(url, { queryArgs: queryParams, cacheOptions: datasourceDef?.cache });
            return data;
        } catch (err) {
            console.error(`error executing useDatasourceDataQuery`, err);
            throw err;
        }
    },
    {
        enabled: !!datasourceDef?.id,
        cacheTime: 10,
        retry: 0
    }
);
