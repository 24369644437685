import { CSSProperties } from "react";
import { LoadingData } from "components/LoadingData/LoadingData";
import { Button } from "react-bootstrap";
import { Icon } from "../Icon/Icon";
import './ActionButton.scss';

export type ActionButtonProps = {
  id?: string;
  label?: string;
  iconName?: string;
  iconClass?: string;
  className?: string;
  variant?:
    'main-action' |
    'secondary-action' |
    'main-action--clear' |
    'main-action--outline' |
    'danger' |
    'danger--outline' |
    'main-action--dark' |
    'primary-action--outline';
  size?: 'small' | 'medium';
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  onClick?: () => void;
  type?: 'button' | 'submit';
  rotateIcon?: number;
  iconPosition?: 'left' | 'right';
  style?:  CSSProperties | undefined;
}

export const ActionButton = (props: ActionButtonProps) => {

  const { 
    loading = false,
    className = '',
    iconName = undefined,
    iconClass = '',
    variant = 'main-action',
    onClick = () => { },
    disabled = false,
    size = 'medium',
    label = undefined,
    title,
    type = 'button',
    id,
    rotateIcon = 0,
    iconPosition = 'left',
  } = props;

  return (
    <div id={id} className={`ActionButton ActionButton--${size} ${loading ? 'ActionButton--loading' : ''}`}>
      <Button
        title={title}
        className={`ActionButton__button ${iconPosition === "right" ? "ActionButton__reverse" : ""} ${className} ${iconClass} ${label ? '' : 'ActionButton__button--no-label'}`}
        variant={variant}
        onClick={onClick}
        disabled={disabled || loading}
        type={type}
      >
        {!loading && <>
          {iconName && <Icon name={iconName} style={({transform: `rotate(${rotateIcon - (iconPosition === "right" ? 180 : 0)}deg)`})} className={`ActionButton__icon ${iconClass}`} />}
          <div className={`ActionButton__label ${iconPosition === "right" ? "ActionButton__reverse" : ""}`}>
            {label && <span style={({ width: `${loading ? label?.length + 'ch' : 'auto'}` })}>{label}</span>}
          </div>
        </>}
        {loading &&
          <LoadingData
            className="ActionButton__spinner"
            data-testid="loadingSpinner"
            animation="border"
          />
        }
      </Button>
    </div >
  );
}
