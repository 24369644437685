import './GenericInteractionCard.scss'
import { Icon } from "components/Icon/Icon";
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from "moment";
import { Interaction, InteractionStatus } from "pages/Interaction/server/services/entity/Interaction";
import { ColorBadge } from "components/ColorBadge/ColorBadge";

export type InteractionCardProps = {
    onPress: () => void;
    type: string;
    code?: string;
    interaction: Interaction;
    icon?: {
        name: string;
        backgroundColor: string;
    };
    previousStatus?: InteractionStatus | null | undefined;
    newStatus?: InteractionStatus | null | undefined;
    userName?: string;
    interactionDate?: Date;
    row?: any;
} & {
    children?: React.ReactNode
}

export const GenericInteractionCard = (props: InteractionCardProps) => {
    const iconColor = props.icon?.backgroundColor ?? undefined;
    return (
        <div className="GenericInteractionCard">
            <div onClick={props.onPress ? props.onPress : () => { }}>
                <div className="standard-card">

                    {!!props.icon &&
                        <Icon name={props.icon.name} className="interaction-icon" style={{ backgroundColor: iconColor }} />
                    }
                    {!props.icon &&
                        <AssignmentIcon className="interaction-icon" />
                    }
                    <div className="interaction-header">
                        <div className="interaction-title">
                            {!!props.code && <label className="interaction-code">{`#${props.code}`}</label>}
                            {!!props.type && <label className="interaction-type">{`${props.type}`}</label>}
                        </div>
                        <label className="interaction-time">{moment(props.interaction.dtModified).fromNow()}</label>
                    </div>
                </div>
                <div className="interaction-extra-container">
                    {props.children}
                    {(!!props.userName || !!props.interactionDate) && <div className="interaction-user-date">
                        {!!props.userName && <label>{props.userName}</label>}
                        {(!!props.userName && !!props.interactionDate) && <FiberManualRecordIcon className="interaction-dot-icon" />}
                        {!!props.interactionDate && <label>{moment(props.interactionDate).format('ddd, D MMM H:mm A')}</label>}
                    </div>}
                    {(!!props.previousStatus || !!props.newStatus) && <div className="interaction-status-bar">
                        {!!props.previousStatus && <ColorBadge width="70px" height="16px" fontSize="10px" fontWeight="600px"
                            semanticValue={props.previousStatus.cdSemanticValue ?? ""}
                            text={props.previousStatus.dsStatus ?? ""} />
                        }

                        {!!props.previousStatus && !!props.newStatus && (
                            <TrendingFlatIcon className="interaction-arrow-icon" />
                        )}

                        {!!props.newStatus && <ColorBadge width="70px" height="16px" fontSize="10px" fontWeight="600px"
                            semanticValue={props.newStatus.cdSemanticValue ?? ""}
                            text={props.newStatus.dsStatus ?? ""} />
                        }
                    </div>}
                </div>
            </div>
        </div>);
}