import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { memoize } from "lodash";

const delayedCalls: { [key: string]: any } = {};

export enum ArrayOrder {
    ASC = 1,
    DESC = -1
}
/**
 * Atomically calls a function after a delay.
 * If the function is called more than once before the delay ends, the previous call will be canceled.
 * @param callbackFunction: the function to be called after the delay.
 * @param delay: delay in milliseconds, the default value is 500ms.
 * @param delayedCallKey: the key name with which the delayed call will be stored in the stack.
 **/
export const callWithDelay = (
    callbackFunction: () => void,
    delay: number = 500,
    delayedCallKey: string = ""
): void => {
    let key: string = !delayedCallKey
        ? callbackFunction.valueOf().toString()
        : delayedCallKey;
    const currentCallID = delayedCalls[key];
    if (currentCallID) {
        clearTimeout(currentCallID);
    }

    delayedCalls[key] = setTimeout(() => {
        delayedCalls[key] = null;
        callbackFunction();
    }, delay);
};

/**
 * Deep Clone of objects
 * @param item the object
 * @returns a clone of the object
 */
export const deepClone = (item: any) => {
    if (!item) { return item; } // null, undefined values check

    let types = [Number, String, Boolean],
        result: any;

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function (type) {
        if (item instanceof type) {
            result = type(item);
        }
    });

    if (typeof result == "undefined") {
        if (Object.prototype.toString.call(item) === "[object Array]") {
            result = [];
            item.forEach(function (child: any, index: any, array: any) {
                result[index] = deepClone(child);
            });
        } else if (typeof item == "object") {
            // testing that this is DOM
            if (item.nodeType && typeof item.cloneNode == "function") {
                result = item.cloneNode(true);
            } else if (!item.prototype) { // check that this is a literal
                if (item instanceof Date) {
                    result = new Date(item);
                } else {
                    // it is an object literal
                    result = {};
                    for (const i in item) {
                        result[i] = <any>deepClone(item[i]);
                    }
                }
            } else {
                // depending on what you would like here,
                // just keep the reference, or create new object
                if (false && item.constructor) {
                    // would not advise you to do that, reason? Read below
                    result = new item.constructor();
                } else {
                    result = item;
                }
            }
        } else {
            result = item;
        }
    }

    return result;
}

export function sortArrayByOrder<T, K extends keyof T>(
    array: T[],
    order: ArrayOrder = 1,
    param: K | "$index" = "$index"
): Omit<T & { $index: number }, "$index">[] {
    return array.map((obj, $index) => ({ ...obj, $index }))
        .sort((a, b) => ((a[param] > b[param]) ? order : order * -1))
        .map(({ $index, ...obj }) => obj);
}

export const b64toBlob = (b64Data: string, contentType: string = '', sliceSize = 512): Blob => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize),
            byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const printFile = (file: Blob) => {
    const blobUrl = URL.createObjectURL(file);
    const iframeElem: HTMLIFrameElement = document.createElement('iframe');
    iframeElem.setAttribute('src', blobUrl);
    iframeElem.setAttribute('style', 'position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;');
    const printWindow = window.open();
    printWindow?.document.body.append(iframeElem)
    setTimeout(() => {
        if (iframeElem != null) {
            iframeElem.focus();
            iframeElem.contentWindow?.print();
        }

    }, 300)
}

export const memoizedAxiosGet = memoize(async  <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => await axios.get(url, config));
export const memoizedAxiosPost = memoize(async  <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> => await axios.post(url, data, config));



export const compare = (a: any, b: any): boolean => {
    return !`${a}` || `${b}`.toLocaleLowerCase().indexOf(`${a}`.toLocaleLowerCase()) > -1
}
