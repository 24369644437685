import { format, parse, set } from 'date-fns'
import { startOfDay, startOfWeek, startOfMonth, startOfYear } from 'date-fns'
import { endOfDay, endOfWeek, endOfMonth, endOfYear } from 'date-fns'
import { addDays, addWeeks, addMonths, addYears, subDays, subWeeks, subMonths, subYears } from 'date-fns'
import { addHours, addMinutes, addSeconds, subHours, subMinutes, subSeconds } from 'date-fns'
import { differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns'
import { Resources } from 'resources/Resources'

export type DateMeasure = 'day' | 'week' | 'month' | 'year';
export type TimeMeasure = 'hour' | 'minute' | 'second';

export class DateHelper {

    private constructor() { }

    static date(
        date: Date = new Date(),//TODO: dates must be UTC
        hours?: number,
        minutes?: number,
        seconds?: number,
        milliseconds?: number): Date {

        if (hours !== undefined &&
            minutes !== undefined &&
            seconds !== undefined &&
            milliseconds !== undefined)
            return set(date, { hours, minutes, seconds, milliseconds });
        return date;
    }

    static now(): Date {
        return new Date();//TODO: dates must be UTC
    }

    static today(
        hours?: number,
        minutes?: number,
        seconds?: number,
        milliseconds?: number): Date {
        if (hours !== undefined &&
            minutes !== undefined &&
            seconds !== undefined &&
            milliseconds !== undefined)
            return set(new Date(), { hours, minutes, seconds, milliseconds });
        return new Date();
    }

    static format(value: any, formatStr: string): string {
        if (value instanceof Date) {
            return format(value, formatStr);
        } else {
            const time = new Date(`2000-01-01 ${value}`);
            return format(time, formatStr);
        }
    }

    static parse(date: string, formatStr: string, dateRef: Date): Date {
        return parse(date, formatStr, dateRef);
    }

    static getTimeOfDayGreeting(): string {
        const timeGreetings: [number, string][] = [
            [18, Resources.get("CommandCenter", "cardPropertiesGreetingEvening", "label")],
            [12, Resources.get("CommandCenter", "cardPropertiesGreetingAfternoon", "label")],
            [0, Resources.get("CommandCenter", "cardPropertiesGreetingMorning", "label")]
        ],
            hour = this.now().getHours();
        let greeting = '';
        for (var i = 0; i < timeGreetings.length; i++) {
            if (hour >= timeGreetings[i][0]) {
                greeting = timeGreetings[i][1]
                break;
            }
        }
        return greeting;
    }

    static startOf(type: DateMeasure, date: Date = new Date()): Date {
        switch (type) {
            case 'day':
                return startOfDay(date);
            case 'week':
                return startOfWeek(date)
            case 'month':
                return startOfMonth(date)
            case 'year':
                return startOfYear(date)
        }
    }

    static endOf(type: DateMeasure, date: Date = new Date()): Date {
        switch (type) {
            case 'day':
                return endOfDay(date)
            case 'week':
                return endOfWeek(date)
            case 'month':
                return endOfMonth(date)
            case 'year':
                return endOfYear(date)
        }
    }

    static addDate(type: DateMeasure, amount: number, date: Date = new Date()): Date {
        switch (type) {
            case 'day':
                return addDays(date, amount)
            case 'week':
                return addWeeks(date, amount)
            case 'month':
                return addMonths(date, amount)
            case 'year':
                return addYears(date, amount)
        }
    }

    static subsDate(type: DateMeasure, amount: number, date: Date = new Date()): Date {
        switch (type) {
            case 'day':
                return subDays(date, amount)
            case 'week':
                return subWeeks(date, amount)
            case 'month':
                return subMonths(date, amount)
            case 'year':
                return subYears(date, amount)
        }
    }

    static addTime(type: TimeMeasure, amount: number, date: Date = new Date()): Date {
        switch (type) {
            case 'hour':
                return addHours(date, amount)
            case 'minute':
                return addMinutes(date, amount)
            case 'second':
                return addSeconds(date, amount)
        }
    }

    static subsTime(type: TimeMeasure, amount: number, date: Date = new Date()): Date {
        switch (type) {
            case 'hour':
                return subHours(date, amount)
            case 'minute':
                return subMinutes(date, amount)
            case 'second':
                return subSeconds(date, amount)
        }
    }

    static diffDate(startDate: Date, endDate: Date, type: DateMeasure): number {
        switch (type) {
            case 'day':
                return differenceInDays(startDate, endDate);
            case 'week':
                return differenceInWeeks(startDate, endDate);
            case 'month':
                return differenceInMonths(startDate, endDate);
            case 'year':
                return differenceInYears(startDate, endDate);
        }

    }

    static diffTime(startDate: Date, endDate: Date, type: TimeMeasure): number {
        switch (type) {
            case 'hour':
                return differenceInHours(startDate, endDate);
            case 'minute':
                return differenceInMinutes(startDate, endDate);
            case 'second':
                return differenceInSeconds(startDate, endDate);
        }
    }

    static formatUTC(value: Date): Date{
        const adjustedDate = (value: Date) => {
            return new Date(
                value.getUTCFullYear(),
                value.getUTCMonth(),
                value.getUTCDate(),
                value.getUTCHours(),
                value.getUTCMinutes(),
                value.getUTCSeconds(),
            );
        };

        return adjustedDate(value);
    }
};
