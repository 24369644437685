import axios from "axios";
import { Endpoints } from '../config/endpoints';
import { ICustomer, ICustomerFilter, ICustomerStatus } from "../models/entities/Customer";

interface ICustomerService {
  getCustomersByRegions(regionId: number, params?: Partial<ICustomerFilter>): Promise<ICustomer[]>;
  getCustomersById(customerId: string ): Promise<ICustomer>;
  getCustomers(params: { idAccount: string | undefined }): Promise<ICustomer[]>;
  getStatus(): Promise<ICustomerStatus[]>;
}

// TODO: Update according to real service query params syntax
const customerServiceUrl = Endpoints.getEndpoint("REACT_APP_CUSTOMER_ENDPOINT");

export const CustomerService: ICustomerService = {
  getCustomersByRegions: (regionId: number, params?: Partial<ICustomerFilter> ): Promise<ICustomer[]> => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.get<ICustomer[]>(`${ customerServiceUrl }customer/customerRegion/getCustomersByRegions/${regionId}`, {params});
      resolve(response.data as ICustomer[]);
    })
  },
  getCustomersById: (customerId: string ): Promise<ICustomer> => {
    return new Promise(async (resolve, reject) => {
      const response = await axios.get<ICustomer[]>(`${ customerServiceUrl }customer/getCustomer/${customerId}`);
      resolve(response.data?.[0] as ICustomer);
    })
  },
  getCustomers: async (params: { idAccount: string | undefined }): Promise<ICustomer[]> => {
    const { data } = await axios.get<ICustomer[]>(`${ customerServiceUrl }customer/getCustomers`, { params });
    return Promise.resolve(data);
  },
  getStatus: async (): Promise<ICustomerStatus[]> => {
    const url = `${customerServiceUrl}customer/status/list`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
} as ICustomerService;
