import { InputSearchEntity, InputSearchGroup, InputSearchOption } from "components/InputSearch/InputSearch";
import { capitalize } from "lodash";
import { ISearchResultDoc } from "models/Search/SearchResult";

export class SearchHelper {

  static mapSearchGroup(entity: string): InputSearchGroup {
    return {
      label: capitalize(entity),
      options: []
    }
  }

  static mapSearchOptions(rawData: ISearchResultDoc[], entity: InputSearchEntity, idField: string): InputSearchOption[] {
    const replaceSpecialChars = (search: string): string =>{
      const result = search.replace(/%25/gi,'%')
        .replace(/%23/gi,'#')
        .replace(/%2B/gi,'+')
        .replace(/%26/gi,'&')
      return result;
    }

    const data = rawData.map(x=>{
      return Object.keys(x.highlights).map(item=>{
        return {
          value: x.highlights[item].join(),
          type: item,
          id: x.document[idField],
          document: x.document
        }
      })
    }).flatMap(x=>x).filter(x => !x.document.cdAccount.includes("***"));

    return data.reduce((acc: InputSearchOption[], elem: any) => {
      if(acc.find(x=>x.content === replaceSpecialChars(elem.value) && x.itemId === elem.id) === undefined){
        acc.push({
            content: replaceSpecialChars(elem.value),
            type: elem.type,
            entity,
            highlight: replaceSpecialChars(elem.value),
            relativePath: "",
            itemId: elem.id,
            document: elem.document
        });
      }
      return acc;
    }, [])
  }
}