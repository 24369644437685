import React, { useEffect, useRef, useState } from "react";
import GenericFilter, { GenericFilterElement, FilterValue, GenericFilterType } from "components/GenericFilters/GenericFilter";
import "./GenericListFilter.scss";
import { DateHelper } from "helpers/DateHelper";

interface IGenericListFilter {
    isFilterPanelOpen: boolean;
    filters: GenericFilterElement[];
    onFiltersChange: (filterValue: FilterValue, filterId: string) => any;
    displayCount: string;
    totalCount: string;
}

const GenericListFilter = (props: IGenericListFilter) => {
    const { isFilterPanelOpen, filters, displayCount, totalCount, onFiltersChange } = props;
    const genericFilterRef = useRef();

    const handleFilterChange = (filterValue: FilterValue, filterId: string) => {
        onFiltersChange(filterValue, filterId);
    }

    const getDefault = (type: GenericFilterType) => {
        switch (type) {
            case "daterange":
                const start: Date = DateHelper.startOf('day', DateHelper.startOf('week', DateHelper.now()));
                const end: Date = DateHelper.endOf('day', DateHelper.endOf('week', DateHelper.now()));
                return { from: start, to: end };
            case "multiselect":
                return [];
            case "toogle":
                return false;
        }
    }

    const onClearFilters = () => {
        if ((genericFilterRef?.current as any).reset) {
            (genericFilterRef.current as any).reset();
        } else {
            filters.forEach(filter => {
                switch (filter.type) {
                    case "status":
                        handleFilterChange([] as any, filter.id);
                        break;
                    default:
                        handleFilterChange({ field: filter.value?.field || "", value: getDefault(filter.type) }, filter.id);
                        break;
                }
            });
        }
    }

    useEffect(() => {
        document.documentElement.style.setProperty("--filter-visible", isFilterPanelOpen ? "1" : "0");
    }, [isFilterPanelOpen])


    return (
        <div className={`GenericListFilter ${isFilterPanelOpen ? ' open' : ''}`}>
            <div className="frame">
                <div className="header">
                    <div className="title">Filters</div>
                    <div className="count">{`Displaying ${displayCount} of ${totalCount} Records`}</div>
                    <small onClick={onClearFilters} className='filter-clear'>
                        Clear All
                    </small>
                </div>
                <div className="filters">
                    <GenericFilter ref={genericFilterRef} filters={filters.filter(x => x.enabled !== false)} onFilterChange={handleFilterChange} />
                </div>
            </div>
        </div >
    );

};

export default GenericListFilter;
