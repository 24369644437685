import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { MutableRefObject, forwardRef, useImperativeHandle, useRef } from "react";
import { RangeModifier } from "react-day-picker";
import DateRangeFilter, { IDateRangeFilter } from "./DateRangeFilter/DateRangeFilter";
import "./GenericFilter.scss";
import MultiSelectFilter, { IMultiSelectFilter } from "./MultiSelectFilter/MultiSelectFilter";
import { IStatusFilterProps, StatusFilter } from "./StatusFilter/StatusFilter";
import ToogleFilter, { IToogleFilter } from "./ToogleFilter/ToogleFilter";
import { ITextFilterProps, TextFilter } from "./TextFilter/TextFilter";

export type GenericFilterType = "daterange" | "toogle" | "multiselect" | "status" | "text";

interface IGenericFilter {
    filters: GenericFilterElement[];
    onFilterChange: (filterValue: FilterValue, filterId: string) => any;
}

export interface FilterValue {
    value: Date | RangeModifier | boolean | string[] | undefined;
    field: string | string[];
}

export interface GenericFilters {
    defaultVisibility: boolean;
    filters: GenericFilterElement[];
}

export interface GenericFilterElement {
    id: string;
    type: GenericFilterType;
    name: string;
    properties: IDateRangeFilter | IMultiSelectFilter | IToogleFilter | IStatusFilterProps | ITextFilterProps;
    enabled?: boolean;
    value?: FilterValue;
    defaultExpanded?: boolean;
}

const GenericFilter = forwardRef((props: IGenericFilter, ref) => {
    const { filters, onFilterChange } = props;
    const elRefs: any = useRef([]);

    const reset = () => {
        if (elRefs?.current) {
            elRefs.current.forEach((ref: any) => {
                if (ref.reset) {
                    ref.reset();
                }
            });
        }
    };

    useImperativeHandle(ref, () => ({
        reset: reset
    }));

    const renderFilter = (filter: GenericFilterElement, index: number) => {
        switch (filter.type) {
            case "daterange":
                return <DateRangeFilter value={(filter.value?.value as RangeModifier)} dateRange={filter.properties as IDateRangeFilter} filterId={filter.id} onFilterChange={onFilterChange}></DateRangeFilter>
            case "multiselect":
                return <MultiSelectFilter value={(filter.value?.value as string[])} multiSelect={filter.properties as IMultiSelectFilter} filterId={filter.id} onFilterChange={onFilterChange}></MultiSelectFilter>
            case "toogle":
                return <ToogleFilter value={(filter.value?.value as boolean)} toogle={filter.properties as IToogleFilter} filterId={filter.id} onFilterChange={onFilterChange}></ToogleFilter>
            case "status":
                return <StatusFilter
                    ref={ref => {
                        elRefs.current[index] = ref
                    }}
                    filterId={filter.id}
                    onFilterChange={onFilterChange}
                    value={(filter.value?.value as string[])}
                    datasource={(filter.properties as any).datasource}
                    applyTo={(filter.properties as any).applyTo}
                />
            case "text":
                return <TextFilter
                    ref={ref => {
                        elRefs.current[index] = ref
                    }}
                    filterId={filter.id}
                    textLabel={(filter.properties as any).textLabel}
                    applyTo={(filter.properties as any).applyTo}
                    onFilterChange={onFilterChange}
                />
        }
    }

    return (
        <div className="GenericFilter">{
            filters.map((filter, index) =>
            (
                <Accordion defaultExpanded={(filter?.properties as any).defaultExpanded} key={`k${index}`} elevation={0} className="accordion">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography>{filter.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderFilter(filter, index)}
                    </AccordionDetails>
                </Accordion>))
        }
        </div>
    );
});

export default GenericFilter;