import { Checkbox } from "@mui/material";
import { QueryDatasourceArgumentsFilter } from "@spring-global/spring-datasources/lib/src/Types";
import { ColorBadge } from "components/ColorBadge/ColorBadge";
import { useDatasourceDataQuery } from "hooks/Datasources/useDatasourceDataQuery";
import { useDatasourceDefinitionQuery } from "hooks/Datasources/useDatasourceDefinitionQuery";
import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FilterValue } from "../GenericFilter";
import "./StatusFilter.scss";

export interface IStatusFilterDatasource {
    id: string;
    params: { [key: string]: string | number | boolean };
}
export interface IStatusFilterProps {
    filterId: string;
    value?: string[];
    field?: string;
    onFilterChange: (filterValue: FilterValue, filterId: string) => any;
    datasource: IStatusFilterDatasource;
    applyTo: string;
}

export const StatusFilter = forwardRef((props: IStatusFilterProps, ref) => {

    const dsParams = Object.keys(props.datasource.params);
    const statusListFilter: QueryDatasourceArgumentsFilter[] = [{
        name: dsParams[0],
        operator: "eq",
        value: props.datasource.params[dsParams[0]]
    }];

    const [statusList, setStatusList] = useState([]);
    const datasourceDef = useDatasourceDefinitionQuery(props.datasource.id, 60);
    const { data, isSuccess } = useDatasourceDataQuery(datasourceDef.data, null!, null!, statusListFilter);
    const [checkedStatus, setCheckedStatus] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
        reset: () => setCheckedStatus([])
    }));

    useEffect(() => {
        if (!isSuccess) return;
        setStatusList((data as any)?.value || data);
    }, [isSuccess]);

    useEffect(() => {
        props.onFilterChange(checkedStatus as any, props?.filterId!);
    }, [checkedStatus])

    const onChangeCheckboxStatus = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const target = event.target as HTMLInputElement;
        if (target && checked) {
            setCheckedStatus(prev => [...prev, target.value]);
        } else {
            setCheckedStatus(prev => prev.filter(x => x !== target.value));
        }
    };

    return (
        <div className="StatusFilter" >
            {
                statusList?.map((status: any) => {
                    return <div key={status.id}>
                        <Checkbox style={{ padding: 0 }}
                            id={status.idstatus}
                            name={status.idstatus}
                            onChange={(e: any, checked: boolean) => { onChangeCheckboxStatus(e, checked) }}
                            checked={checkedStatus.includes(status.idstatus!)}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            value={status.idstatus}
                        />
                        <label htmlFor={status.idstatus}>
                            <ColorBadge
                                text={status.dsstatus}
                                semanticValue={status.cdsemanticvalue}
                            />
                        </label>
                    </div>
                })
            }
        </div >
    );
});
