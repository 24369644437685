import { ISearchConfig } from "models/Search/SearchConfig";
import { useState } from "react";
import { useQuery } from "react-query";
import { SearchService } from "services/general/SearchService";
import { useDebounce } from "use-debounce";

type UseHeaderSearchParams = {
    token?: string;
    minLength: number;
    searchConfig: ISearchConfig[];
}

export const useHeaderSearch = (params: UseHeaderSearchParams) => {
    const {token, minLength, searchConfig } = params;

    const [querySearch, setQuerySearch] = useState('');

    const search = useHeaderSearchQuery(querySearch, minLength, searchConfig, token);

    return {
        search,
        querySearch,
        setQuerySearch
    }
}

export const useHeaderSearchQuery = (q: string, minLength: number, searchConfig: ISearchConfig[], token?: string,) => {
    const enabled = q.length >= minLength;
    return useQuery(
        ['search', q ?? '', token, minLength],
        () => SearchService.getSearch({ q, searchConfig, token }),
        { enabled }
    );
}