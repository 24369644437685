import { DateHelper } from 'helpers/DateHelper';
import React from 'react';
import './CellTemplateDualDate.scss';

export type CellTemplateDualDateProps = {
    text: string;
    subText: string;
    replaceTokens?: string[];
    dateTimeFormat?: string;
    row: any;
};

export const CellTemplateDualDate = (props: CellTemplateDualDateProps) => {

    let text: string = props.text;
    let subText: string = props.subText;

    try {
        if (props.replaceTokens) {
            const len: number = props.replaceTokens.length!;
            let i: number = 0;
            while (i < len) {
                const date: Date = new Date(props.row[props.replaceTokens[i]]);
                const formattedDate: string = DateHelper.format(DateHelper.date(date), props.dateTimeFormat || "yyyy-MM-dd");
                text = text.replaceAll(`{${i}}`, formattedDate);
                subText = subText.replaceAll(`{${i}}`, formattedDate);
                i++;
            }
        }
    } catch (e) {
        console.error(e);
    }

    return (
        <div className="CellTemplateDualDate">
            <div>{text}</div>
            <div>{subText}</div>
        </div>
    );
}

export default React.memo(CellTemplateDualDate);