import './VisitInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";


export const VisitInteractionCard = (props: InteractionCardProps) => {
    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get('InteractionPanelType', 'VISIT','label')}
            code={props.code}
            previousStatus={props.previousStatus}
            newStatus={props.newStatus}
            icon={{
                name: "calendar-today",
                backgroundColor: '#3498DA'
            }}
            interactionDate={props.interactionDate}
            userName={props.userName}
            onPress={()=>{}}
        >
            {!!props.interaction.EntityFields?.nmVisitType && <div className="VisitInteractionCard">
                <label>{props.interaction.EntityFields?.nmVisitType}</label>
            </div>}
        </GenericInteractionCard>
    );
}