import React from "react";
import {
  HeaderAction,
  HeaderSecondaryAction,
} from "../../models/actions/Header";
import "./Header.scss";
import { Icon } from "components/Icon/Icon";
const Button = React.lazy(() => import("react-bootstrap/Button"));

export interface HeaderProps {
  title: string;
  navigate?: string,
  actions?: HeaderAction[];
  secondaryActions?: HeaderSecondaryAction[];
  beta?: boolean;
  children?: React.ReactNode;
  onNavigate?: () => void,
}

export const Header = (props: HeaderProps) => {
  const { title, navigate, children, onNavigate, actions = [], secondaryActions = [], beta } = props;

  const handleOnNavigate = () => {
    if (typeof onNavigate == "function") onNavigate();
  }

  return (
    <div className="Header">
      <div className="HeaderLeft">
        {!navigate &&
          <>
            <h2 className="HeaderLeft__title">{title}</h2>
            {beta === true &&
              <div className="HeaderLeft__beta">
                <span>BETA</span>
              </div>
            }
          </>
        }
        {navigate &&
          <>
            <Button className="HeaderLeft__navigate_icon" onClick={handleOnNavigate} variant="main-navigate">
              <Icon name={"keyboard_arrow_left"} className="alert-icon" />
            </Button>
            <h3 className="HeaderLeft__navigate">{navigate}/</h3>
            <h3 className="HeaderLeft__navigate_title">{title}</h3>
            {beta === true &&
              <div className="HeaderLeft__beta">
                <span>BETA</span>
              </div>
            }
          </>
        }
        <div className="HeaderLeft__Actions">
          {actions.map(({ name, callback }, index) => (
            <Button
              className="action"
              key={index}
              onClick={callback}
              variant="main-action"
            >
              {name}
            </Button>
          ))}
        </div>
      </div>
      <div className="HeaderCenter" />
      <div className="HeaderRight">
        <div className="HeaderOption">
          {secondaryActions.map(({ element, isToggle }, index) => (
            <div
              className={`Option ${isToggle ? "Option--toggle" : ""}`}
              key={index}
            >
              {element}
            </div>
          ))}
          {children}
        </div>
      </div>
    </div>
  );
};
