import './SalesPriorityInteractionCard.scss' 
import { GenericInteractionCard, InteractionCardProps } from "./GenericInteractionCard";
import { Resources } from "resources/Resources";
import { InteractionStatus } from 'pages/Interaction/server/services/entity/Interaction';
import { ColorBadge } from 'components/ColorBadge/ColorBadge';


export const SalesPriorityInteractionCard = (props: InteractionCardProps) => {
    const roles = props.interaction.User?.Roles?.map(r => r.nmRole).join("; ");

    const newStatus: InteractionStatus | undefined = (props.newStatus?.value ?? null) != null  ? {
        value: props.newStatus!.value,
        dsStatus: Resources.get("SalesPriorityInteractionPanel", props.newStatus!.value === "1" ? "StatusYes" : "StatusNo","label"),
        cdSemanticValue: props.newStatus!.value === "1" ? "FINALIZED" : "CANCELLED"
    } : undefined;

    return (
        <GenericInteractionCard
            interaction={props.interaction}
            type={Resources.get("InteractionPanelType", "SLSPI","label")}
            code={props.code}
            icon={{
                name: "verified",
                backgroundColor: "#E74C3B"
            }}
            onPress={()=>{}}
        >
        <div className="SalesPriorityInteractionCard">
            <div className="priority-user-and-status">
                {!!props.userName && <div className="priority-interaction-user">
                    <label>{props.userName}</label>
                </div>}
                {!!newStatus && <ColorBadge width="70px" height="16px" fontSize="10px" fontWeight="600px"
                        semanticValue={newStatus.cdSemanticValue ?? ""}
                        text={newStatus.dsStatus ?? ""}/>
                }                
            </div>
            {!!roles && <div className="priority-user-roles">
                <label>{roles}</label>
            </div>}
        </div>            
        </GenericInteractionCard>
    );
}