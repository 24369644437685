import { SyntheticEvent } from "react";
import BootstrapTable, {
  ColumnDescription,
  PaginationOptions,
  SortOrder,
  TableChangeHandler,
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Base from "../../models/BaseEntity";

import { useAppState } from "../../context/StateProvider";
import SelectActions from "./SelectActions";
import "./SpringGrid.scss";

export interface SpringGridOptions<T = any> {
  // Data to be displayed in the grid that matches the column definition metadata
  data: Base[];

  // Flag that shows that the grid is loading
  loading?: boolean;

  // Flag that allows select if you want to see show selected rows or not
  showSelected?: boolean;

  // Grid column definition metadata
  columns: ISpringColumnDescription[];

  //Unique name of the grid element
  keyField: string;

  // Paginations options
  paginationOptions?: ISpringPaginationOptions;

  onRowClick?: (e: SyntheticEvent, row: T, rowIndex: number) => void;

  defaultSorted?: [{ dataField: any; order: SortOrder }] | undefined;

  rowHover?: boolean;

  handleOnSelect?: (row: T, isSelect: boolean, rowIndex: number, e: SyntheticEvent) => void;
  handleOnSelectAll?: (isSelect: boolean, rows: T[]) => void;
  handleClear?: () => void;
  selected?: string[];
  //Enable remote handle data
  remote?: boolean;

  //Handle onTableChange
  onTableChange?: TableChangeHandler<object> | undefined;

  classes?: string;
}

//Extended class where the custom Spring Pagination Options are defined
export interface ISpringPaginationOptions extends PaginationOptions {
}

//Extended class where the custom Spring Column Description are defined
export interface ISpringColumnDescription extends ColumnDescription {
}

export const SpringGrid = (springGridOptions: SpringGridOptions) => {
  const { identity } = useAppState();
  const {
    data,
    loading,
    columns,
    paginationOptions,
    keyField,
    rowHover = false,
    defaultSorted = [{ dataField: "date", order: "desc" }],
    onRowClick = () => {
    },
    handleOnSelect,
    handleOnSelectAll,
    handleClear = () => {
    },
    selected = [],
    remote,
    onTableChange,
    showSelected,
    classes
  } = springGridOptions;


  const enableSelection = false;//TODO: Move this to component params
  const showSelectionOptions = false;//TODO: Move this to component params

  let pageOptions: PaginationOptions = paginationOptions || {};
  const selectRow = enableSelection ? {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selected,
    classes: 'checkboxList'
  } : undefined;

  return (
    <div
      className={`SpringGrid 
      ${loading ? 'SpringGrid--loading' : ''}
      ${rowHover ? 'SpringGrid--row-hoverable' : ''}
    ` }>
      {showSelectionOptions && enableSelection ?
        <SelectActions
          handleClear={handleClear}
          selectCount={selected.length}
          items={selected}
        />
        :
        undefined}
      <BootstrapTable
        rowEvents={({ onClick: onRowClick })}
        bootstrap4
        keyField={keyField}
        data={data}
        columns={columns}
        pagination={paginationFactory(pageOptions)}
        defaultSorted={defaultSorted}
        bordered={false}
        headerWrapperClasses="SpringGridHeader"
        rowClasses="SpringGridRows"
        // @ts-ignore
        selectRow={selectRow}
        remote={remote}
        onTableChange={onTableChange}
        classes={classes}
      />
    </div>
  );
};
