import * as XLSX from "xlsx";
import {
  ISpringColumnDescription
} from "../../components/SpringGrid/SpringGrid";
import { DateHelper } from "../../helpers/DateHelper";
import { SpringGridResizableOptions } from "components/SpringGridResizable/SpringGridResizable";

// TODO: Move it to separate file
export type ISheetData = {
  data: any[];
  columns: ISpringColumnDescription[];
};

export class FileUtilService {
  /**
   * Reads as much as `limit` row entries from an excel file first sheet, and returns
   * @type ISheetData structure
   * @param file: File metadata object of selected file
   * @param limit: number of first n rows to be read from file. If equals 0, all rows will be read.
   *
   * @returns Promise<ISheetData> with JSON as array of objects with the format {[columnName]: cellValue}
   */
  static readExcelFile(file: File, limit: number = 0): Promise<ISheetData> {
    return new Promise<ISheetData>((resolve, reject) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = (e: any) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
          sheetRows: limit,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const excelData = XLSX.utils.sheet_to_json(ws, { header: 1 });
        resolve(FileUtilService._mapXLSXJson(excelData));
      };
      if (rABS) reader.readAsBinaryString(file);
      else reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Receives a file and returns SpringGridOptions object to use with SpringGrid component
   *
   * @param file
   * @param options
   */
  static getGridOptionsFromExcelFile(
    tabletag: string,
    file: File,
    limit: number = 1000,
    paginationOptions: any = { sizePerPage: 25, showTotal: true },
  ): Promise<SpringGridResizableOptions> {
    return new Promise<SpringGridResizableOptions>(async (resolve, reject) => {
      const { columns, data }: ISheetData = await this.readExcelFile(
        file,
        limit
      );

      resolve({
        localStorageTableTag: tabletag,
        columns,
        data,
        keyField: "id",
        loading: false,
        paginationOptions,
      });
    });
  }

  static _mapXLSXJson(input: any[]): ISheetData {
    const [columns, ...data] = input;
    const mappedColumns = columns.map((dataField: string) => ({
      dataField,
      text: dataField,
      sort: true,
    }));

    return {
      columns: mappedColumns,
      data: data.map((row) => {
        return row.reduce(
          (acum: any, curr: any, index: number) => ({
            ...acum,
            [columns[index]]:
              curr instanceof Date
                ? DateHelper.format(DateHelper.date(curr), "MMM dd yyyy HH:mm:ss aa") // Bootstrap Table doesn't deal with Date fields
                : curr,
          }),
          {}
        );
      }),
    };
  }
}
