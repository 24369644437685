import React from "react";
import { createContext, useContext } from "react";
import { AppSession } from "../models/AppSession";
import { Action, appReducer } from "./reducer";

type Dispatch = (action: Action) => void;
const StateContext = createContext<AppSession | undefined>(undefined);
const DispatchContext = createContext<Dispatch | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

const initialState: AppSession = { identity: undefined };

const StateProvider = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(appReducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

const useAppState = () => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a StateProvider");
  }
  return context;
};

const useAppDispatch = () => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a StateProvider");
  }
  return context;
};

export { StateProvider, useAppState, useAppDispatch };
