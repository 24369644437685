import { InputSearchOption } from "components/InputSearch/InputSearch";
import { createContext, FunctionComponent, ReactElement, useContext, useState } from "react";

type HeaderSearchContextType = {
    selectedOption: InputSearchOption | undefined;
    onSelectOption: (option: InputSearchOption | undefined) => void;
}

interface HeaderSearchProviderProps {
    children: (ReactElement | Element)[];
}

const FiltersProviderContext = createContext<HeaderSearchContextType>({
    selectedOption: undefined,
    onSelectOption: () => { }
});

const HeaderSearchProvider: FunctionComponent<HeaderSearchProviderProps> = (props: HeaderSearchProviderProps) => {

    const { children } = props;

    const [selectedOption, _setselectedOption] = useState<InputSearchOption>();

    const onSelectOption = (option: InputSearchOption | undefined) => {
        _setselectedOption(option);
    }

    return (
        <FiltersProviderContext.Provider value={{ selectedOption, onSelectOption }}>
            {children}
        </FiltersProviderContext.Provider>
    );
}

const useHeaderSearchContext = () => useContext(FiltersProviderContext);

export { HeaderSearchProvider, useHeaderSearchContext };

